import React from 'react';
import _ from 'lodash';
import MediaCard from './MediaCard';

const copyList = [
  {
    name: 'variety',
    alt: 'Coffee Selection',
    copy: 'Expand your palette and discover new roasts each month'
  },
  {
    name: 'traffic',
    alt: 'Traffic',
    copy: 'Free up time from traffic and crowded grocery stores'
  },
  {
    name: 'local',
    alt: 'Premium Coffee',
    copy: 'Enjoy premium flavors while supporting your local businesses'
  }
];

const SubHeader = () => {
  return (
    <div className='subheader'>
      {_.map(copyList, (item, key) => {
        return <MediaCard item={item} rowName={'subheader'} key={key} />;
      })}
    </div>
  );
};

export default SubHeader;
