import React, { useEffect, useState, useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';

import {env} from '../../config/config.js';
import { AuthContext } from '../../contexts/AuthContext';

import OrderList from '../common/OrderList';
import LrcButton from '../common/LrcButton';  
import './checkout.css';

const { host, port } = env;

const ConfirmationPage = (props) => {
  const [orderItems, setOrderItems] = useState([]);
  const { dispatch, state } = useContext(AuthContext);

  const [isAccessDenied, setIsAccessDenied] = useState(false);

  useEffect(() => {
    const checkAuthorization = async () => {
      const {isAuthenticated} = state;
      // set is authorized at the top of this
      // if user is authorized, then hit an endpoint to get their dashboard data
      if (isAuthenticated) {
        if (isAccessDenied) setIsAccessDenied(false);
      } else {
        try {
          const jwt = localStorage.getItem('token');
          await axios.get(`${host}:${port}/api/dashboard`, {headers: {'authorization': jwt}});
          if (isAccessDenied) setIsAccessDenied(false);
          await dispatch({
            type: 'AUTHORIZE'
          });
        } catch (e) {
          // if token is expired see if refreshToken works
          await checkRefreshToken();
        }
      }
    }
    checkAuthorization()
    // eslint-disable-next-line
  }, [isAccessDenied])

  useEffect(() => {
    let items = _.get(props, 'location.state.roastItems');
    if (!items) {
      const subscriptionChoice = localStorage.getItem('subscriptionChoice');
      if (subscriptionChoice === 'Blends') {
        items = JSON.parse(localStorage.getItem('orderChoice1'));
      } else if (subscriptionChoice === 'Single Origin') {
        items = JSON.parse(localStorage.getItem('orderChoice3'));
      } else {
        items = JSON.parse(localStorage.getItem('orderChoice2'));
      }
    }
    setOrderItems(items);
    // eslint-disable-next-line
  }, []);

  const checkRefreshToken = async () => {
    try {
      const refreshJWT = localStorage.getItem('rfToken');
      const resp = await axios.post(`${host}:${port}/api/login/token`, {token: refreshJWT});
      const token = resp.data.accessToken;
      await dispatch({
        type: 'REFRESH',
        payload: { token }
      });
      if (isAccessDenied) setIsAccessDenied(false);
      await dispatch({
        type: 'AUTHORIZE'
      });
    } catch (err) {
      // redirect to login
      setIsAccessDenied(true);
    }
  }

  if (isAccessDenied) {
    return <Redirect to={'/login'} />;
  }

  return (
    <div className="checkout-container">
    <div className="inner-container">
      <h1>Payment submitted successfully</h1>
      <OrderList orderItems={orderItems} />
      <Link to='/dashboard'>
        <LrcButton ctaText={'Dashboard'}/>
      </Link>
    </div>
    </div>
  );
};

export default ConfirmationPage;