import React, {useState, useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';

import ResetRoute from './routes/ResetRoute';

import Landing from './containers/Landing';
import Login from './components/login/Login';
import Signup from './components/signup/Signup';
import InitializeReset from './components/reset/InitializeReset';
import ResetPassword from './components/reset/ResetPassword';
import NoMatch from './components/reset/NoMatch';
import ResetSuccess from './components/reset/Success';
import Dashboard from './components/userdashboard/Dashboard';
import Account from './components/account/Account';
import UpdateShipping from './components/account/UpdateShipping';
import Cart from './components/cart/Cart';
import Subscribe from './components/product/Subscribe';
import OrderChoices from './components/product/OrderChoices';
import ReviewOrder from './components/product/ReviewOrder';
import Payment from './components/checkout/Payment';
import ConfirmationPage from './components/checkout/ConfirmationPage';
// import Shop from './components/product/shop/Shop';
import Returns from './components/services/Returns';
import ContactUs from './components/services/ContactUs';
import AboutUs from './components/services/AboutUs';
import Roasters from './components/roasters/RoastersBar';
import RoasterPage from './components/roasters/RoasterPage';
import NotFound from './components/common/NotFound';
import ComingSoon from './components/common/ComingSoon';

import {env} from './config/config';
const {host, port} = env;

const BaseRouter = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/reset" component={InitializeReset} />
        <Route exact path="/reset/:token" component={ResetPassword} />
        <Route exact path="/success" component={ResetSuccess} />
        <Route exact path="/no_match" component={NoMatch} />
        <Route exact path="/dashboard" component={Dashboard} />

        <Route exact path="/account" component={Account} />
        <Route exact path="/update_shipping" component={UpdateShipping} />

        <Route exact path="/cart" component={Cart} />
        <Route exact path="/subscribe" component={Subscribe} />
        <Route exact path="/subscribe/choice" component={OrderChoices} />
        <Route exact path="/subscribe/review" component={ReviewOrder} />

        <Route exact path="/payment" component={Payment} />
        <Route exact path="/confirm" component={ConfirmationPage} />

        {/* <Route exact path='/shop' component={Shop} /> */}
        <Route exact path="/roasters" component={Roasters} />
        <Route path="/roaster/:id" children={RoasterPage} />
        <Route exact path="/blog" component={ComingSoon} />
        <Route exact path="/returns" component={Returns} />
        <Route exact path="/contact" component={ContactUs} />
        <Route exact path="/about" component={AboutUs} />

        <NotFound default={true} component={NotFound} />
      </Switch>
    </div>
  );
};

export default BaseRouter;
