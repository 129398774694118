import React from 'react';
import _ from 'lodash';
import SubscriptionCard from './SubscriptionCard';
import './subscription.css';
// forced pipeline push
const options = [
  {
    name: 'Blends',
    description:
      "The blend rotation is great choice for those on a budget. Get high quality coffees at your door - keeping your mornings happy and your wallet happy.",
    price: 1797,
    image: 'https://lrc-assets.s3.us-east-2.amazonaws.com/blends.jpg'
  },
  {
    name: 'Mix',
    description: "Our most popular choice- get a mix of blend and single origins. The most variety for a great value.",
    price: 1897,
    image: 'https://lrc-assets.s3.us-east-2.amazonaws.com/mix.jpg'
  },
  {
    name: 'Single Origin',
    description:
      "Connoisseur's top choice. The best coffees our roasters have to offer. Get your micro-lots and nano-lots here.",
    price: 1997,
    image: 'https://lrc-assets.s3.us-east-2.amazonaws.com/sorigin.jpg'
  }
];

const Subscribe = props => {
  let updating;
  if (props.location && props.location.state) {
    (updating = props.location.state.updating);
  }
  return (
    <div className="subscription-container">
      <div className="inner-sub-container">
        {_.map(options, (item, key) => {
          return (
            <SubscriptionCard
              updating={updating}
              item={item}
              key={key}
              name={item.name}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Subscribe;
