import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import LrcButton from '../common/LrcButton';
import UpdateShipping from './UpdateShipping';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
    minWidth: 450,
    minHeight: 300,
    justifyContent: 'center',
    alignItems: 'center',
    // boxShadow: '2px 2px 5px 5px #6a7933',
    // border: '2px solid #f18f36',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 36,
  },
  pos: {
    fontSize: 18,
    marginBottom: 12,
  },
  button: {
    marginTop: '5px',
    width: 200,
  },
});

const DisplayAddress = (props) => {
  const classes = useStyles();
  const [isUpdatingAddress, setIsUpdatingAddress] = useState(props.isUpdating);

  const toggleUpdateAddress = () => {
    setIsUpdatingAddress(!isUpdatingAddress);
  }

  useEffect(() => {
    if (props.isUpdating) {
      setIsUpdatingAddress(false);
    }
  },
  [])

  return (
    <div className={classes.content}>
      {isUpdatingAddress ? 
    (
      <>
        <UpdateShipping toggleUpdateAddress={toggleUpdateAddress} />
        <div className={classes.button} onClick={toggleUpdateAddress}>
          <LrcButton size="small" ctaText={'Cancel'}>Update Address</LrcButton>
        </div>
      </>
    ) :
    (
    <>
    <Card className={classes.root}>
      <CardContent>
        {/* <Typography className={classes.title} color="textSecondary" gutterBottom>
          Word of the Day
        </Typography> */}
        <Typography className={classes.title}>
          {props.name}
        </Typography>
        {/* <Typography className={classes.pos} color="textSecondary">
          {`${props.address.line1} \n ${props.address.line2} \n ${props.address.city}, ${props.address.state} ${props.address.postal_code}`}
        </Typography> */}
        <Typography className={classes.pos}>
        {`${props.address.line1} \n ${props.address.line2}`}
        <br/>
        {`${props.address.city}, ${props.address.state} ${props.address.postal_code}`}
        </Typography>
      </CardContent>
      <CardActions>
      </CardActions>
    </Card>
    <div className={classes.button} onClick={toggleUpdateAddress}>
      <LrcButton size="small" ctaText={'Update Address'}>Update Address</LrcButton>
    </div>
    </>)}
    </div>
  );
}

export default DisplayAddress;