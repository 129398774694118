import React, {useContext, useState, useEffect} from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';

// MUI Styles
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import {env} from '../../config/config.js';
import {AuthContext} from '../../contexts/AuthContext';
import './login.css';

const {host, port} = env;

const LoginBtn = withStyles({
  root: {
    background: '#f18f36',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(90, 90, 27, 0.3)',
    color: 'black',
    height: 48,
    width: '10vh',
    padding: '10px 30px',
    marginBottom: '10%',
  },
})(Button);

const Login = (props) => {
  const {dispatch} = useContext(AuthContext);
  const initialState = {
    email: '',
    username: '',
    password: '',
    isSubmitting: false,
  };
  const [data, setData] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkAuthorization = async () => {
      try {
        const jwt = localStorage.getItem('token');
        await axios.get(`${host}:${port}/api/login/authenticate`, {headers: {authorization: jwt}});
        setIsLoggedIn(true);
        await dispatch({
          type: 'AUTHORIZE',
        });
      } catch (e) {
        // try looking for refreshToken
        try {
          const refreshJWT = JSON.parse(localStorage.getItem('rfToken'));
          const resp = await axios.post(`${host}:${port}/api/login/token`, {token: refreshJWT});
          const token = resp.data.accessToken;
          await dispatch({
            type: 'REFRESH',
            payload: {token},
          });
          setIsLoggedIn(true);
          await dispatch({
            type: 'AUTHORIZE',
          });
        } catch (e) {
          // redirect to login
          setIsLoggedIn(false);
        }
      }
    };
    checkAuthorization();
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const validateSubmission = (data) => {
    const {email, username, password} = data;

    const isBlankField = _.reduce(
      data,
      (acc, value, key) => {
        if (key === 'email' || key === 'password') {
          if (!value.length) {
            return false;
          }
        }
        return acc && true;
      },
      true
    );

    if (!isBlankField) {
      setErrorMessage(['Field is required']);
      return false;
    }
    if (!email.includes('@')) {
      setErrorMessage(['Not a valid email']);
      return false;
    }
    if (email.length > 255 || username.length > 50 || password.length > 40) {
      setErrorMessage(['Please fix your entry and try again']);
      return false;
    }
    return true;
  };

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setData({
      ...data,
      isSubmitting: true,
    });
    const isValid = validateSubmission(data);
    if (!isValid) {
      setData({
        ...data,
        isSubmitting: false,
      });
      return null;
    }
    axios
      .post(`${host}:${port}/api/login`, {
        username: '',
        email: data.email,
        password: data.password,
      })
      .then((res) => {
        try {
          setData({
            ...data,
            isSubmitting: false,
          });
          return res.data;
        } catch (e) {
          // do nothing?
        }
      })
      .then((result) => {
        dispatch({
          type: 'LOGIN',
          payload: {
            user: data.email,
            token: result.accessToken,
            refreshToken: result.refreshToken,
            customerId: result.customerId,
          },
        });
      })
      .then(() => {
        setIsLoggedIn(true);
      })
      .catch((err) => {
        const errorMsg = [];
        if (typeof _.get(err, 'response.data.errors') === 'object') {
          _.forEach(err.response.data, (e) => {
            errorMsg.push(e[0].msg);
          });
        } else {
          errorMsg.push('Ooops something went wrong');
        }
        setData({
          ...data,
          isSubmitting: false,
        });
        setErrorMessage(errorMsg);
      });
  };

  // TODO: get history working
  const referer = _.get(props, 'location.state.referer') || '/dashboard';

  if (isLoggedIn) {
    if (sessionStorage.getItem('continueToPayment') === 'true') {
      sessionStorage.removeItem('continueToPayment');
      return <Redirect to={'/payment'} />;
    }
    return <Redirect to={referer} />;
  }
  // const [loading, setLoading] = React.useCallback(false);
  // const { error, showError } = useErrorHandler(null); // this is for custom hook

  // const logout = () => {
  //   localStorage.removeItem('user');
  //   localStorage.removeItem('expirationDate');
  // };

  // const checkAuthTimeout = expirationDate => {
  //   return dispatch => {
  //     setTimeout(() => {
  //       dispatch(logout());
  //     }, expirationTime * 1000);
  //   };
  // };

  // const authLogin = (username, password) => {
  //   return dispatch => {
  //     dispatch(authStart());
  //     axios
  //       .post('http://localhost:8000/rest-auth/login/', {
  //         username: username,
  //         password: password
  //       })
  //       .then(res => {
  //         const token = res.data.key;
  //         const expirationDate = new Date(new Date().getTime() + 3600 * 1000); // gives one hour in the future
  //         localStorage.setItem('token', token);
  //         localStorage.setItem('expirationDate', expirationDate);
  //         dispatch(authSuccess(token));
  //         dispatch(checkAuthTimeout(3600));
  //       })
  //       .catch(err => {
  //         dispatch(authFail(err));
  //       });
  //   };
  // };

  return (
    <div className="login-container">
      <div className="inner-container">
        <h1>Login</h1>
        {errorMessage
          ? _.map(errorMessage, (msg, index) => {
              return (
                <div className="error">
                  <Alert severity="error">{msg}</Alert>
                </div>
              );
            })
          : null}
        <form
          className="login-form"
          name="login-form"
          noValidate
          autoComplete="on"
          method="POST"
          onSubmit={handleFormSubmit}>
          <TextField
            id="outlined-email-input"
            label="Email"
            type="email"
            name="email"
            autoComplete="email"
            margin="normal"
            variant="outlined"
            value={data.email}
            onChange={handleInputChange}
          />
          <TextField
            id="outlined-password-input"
            label="Password"
            type="password"
            name="password"
            autoComplete="current-password"
            margin="normal"
            variant="outlined"
            value={data.password}
            onChange={handleInputChange}
          />
          <LoginBtn type="submit" value="Submit">
            {data.isSubmitting ? 'Loading...' : 'Login'}
          </LoginBtn>
          <div className="sign-up-txt">
            <p>
              New to Local Roast Club?
              <NavLink style={{marginRight: '10px'}} to="/signup">
                {' '}
                Sign Up
              </NavLink>
            </p>
            <div className="reset">
              <Link to="/reset">Forgot Password</Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
