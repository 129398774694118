import React from 'react';
import {ReactComponent as Bean} from '../../svg/bean.svg';

const ListItem = (props) => {
  const bull = <Bean style={{width: '50px', height: '30px', paddingTop: '0.5vh'}} alt="bullet point" />;
  return (
    <ul style={{display: 'flex', alignItems: 'flexStart'}}>
      <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>{bull}</div>
      <p style={{display: 'flex', alignItems: 'center', flexGrow: '2'}}>{props.children}</p>
    </ul>
  );
};

export default ListItem;
