import React, {useState, useContext, useEffect} from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';

import {env} from '../../config/config.js';
import { AuthContext } from '../../contexts/AuthContext';

import DashboardBtn from './DashboardBtn';
import OrderItems from './OrderItems';
import ConfirmModal from './ConfirmModal';
import FreezeModal from './FreezeModal';
import {getNextShipDate} from './shipdate';
import './dashboard.css';

const { host, port } = env;

const Dashboard = () => {
  const { dispatch, state } = useContext(AuthContext);

  const initialState = {
    email: '',
    username: '',
    name: '',
    // orderItems: orderItems,
    shipDate: '',
    totalPrice: (0).toFixed(2)
  };

  const [userData, setUserData] = useState(initialState);

  const [orderItems, setOrderItems] = useState([]);
  const [subscriptionType, setSubscriptionType] = useState('');

  // const [isUpdating, setIsUpdating] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openFreezeModal, setOpenFreezeModal] = useState(false);
  const [isAccessDenied, setIsAccessDenied] = useState(false);

  useEffect(() => {
    const checkAuthorization = async () => {
      const {isAuthenticated} = state;
      // set is authorized at the top of this
      // if user is authorized, then hit an endpoint to get their dashboard data
      if (isAuthenticated) {
        if (isAccessDenied) setIsAccessDenied(false);
      } else {
        try {
          const jwt = localStorage.getItem('token');
          await axios.get(`${host}:${port}/api/dashboard`, {headers: {'authorization': jwt}});
          if (isAccessDenied) setIsAccessDenied(false);
          await dispatch({
            type: 'AUTHORIZE'
          });
        } catch (e) {
          // if token is expired see if refreshToken works
          await checkRefreshToken();
        }
      }
    }
    checkAuthorization()
    // eslint-disable-next-line
  }, [isAccessDenied])

  const checkRefreshToken = async () => {
    try {
      const refreshJWT = localStorage.getItem('rfToken');
      const resp = await axios.post(`${host}:${port}/api/login/token`, {token: refreshJWT});
      const token = resp.data.accessToken;
      await dispatch({
        type: 'REFRESH',
        payload: { token }
      });
      if (isAccessDenied) setIsAccessDenied(false);
      await dispatch({
        type: 'AUTHORIZE'
      });
    } catch (err) {
      // redirect to login
      setIsAccessDenied(true);
    }
  }

  const formatPrice = (str) => {
    const i = _.get(str, 'length', 0);
    const price = i > 0 ? `${str.slice(0, i - 2)}.${str.slice(i - 2)}` : 0;
    return price;
  }

  const getOrders = async () => {
    try {
      const customerId = JSON.parse(localStorage.getItem('customerId')); // TODO: change to sessionStorage
      const resp = await axios.get(`${host}:${port}/api/order?customerId=${customerId}`);
      const subscriptionTier = _.get(resp, 'data.subscription_choice');
      setSubscriptionType(subscriptionTier)
      const items = _.get(resp, 'data.items');
      const price = formatPrice(_.get(resp, 'data.price'), '');
      if (items) {
        setOrderItems(items);
      } else {
        setOrderItems([]);
      }
      setUserData({
        ...userData,
        name: _.get(resp, 'data.first_name'),
        totalPrice: price
      })
    } catch (e) {
      // handle e
    }
  }

  useEffect(() => {
    getOrders()
    // eslint-disable-next-line
  }, [])

  const updateSubDashboard = async () => {
    await getOrders();
  }

  const confirmCancel = () => {
    // popup modal to confirm, if yes, then execute cancelSubscription
    setOpenConfirmModal(!openConfirmModal);
  }

  const freezeModal = () => {
    setOpenFreezeModal(!openFreezeModal);
  }

  if (isAccessDenied) {
    return <Redirect to={'/login'} />;
  }

  return (
    <div className='dashboard-container'>
      <div className='inner-container'>
        <h2>Hey there{userData.name ? ` ${userData.name}` : null}!</h2>
        {!orderItems.length ? 
        (
          <>
          <div className="no-items">No active subscription</div>
          <Link to={{ pathname: '/subscribe', state: {updating: Boolean(orderItems.length)} }} >
            <DashboardBtn ctaText={'Start Subscription'} style={{marginLeft: '15px', marginRight: '15px'}} />
          </Link>
          </>
        ) : 
        (
        <>
        <div className='schedule-box'>Your next order is scheduled for {userData.shipDate || getNextShipDate()}</div>
        <div className='order-box'>
          <h3 className='subscription-type'>{`${subscriptionType} Subscription`}</h3>
            <OrderItems orderItems={orderItems} /> 
            <div className='order-total-box'>
              <div className='button-cell'>
                {/* TODO: I would like to change this according to screen size, when all buttons don't fit use a dropdown */}
                <Link to={{ pathname: '/subscribe', state: {updating: Boolean(orderItems.length)} }} >
                  <DashboardBtn ctaText={'Update Subscription'} style={{marginLeft: '15px', marginRight: '15px'}} />
                </Link>

                {/* <div onClick={freezeModal}>
                  <DashboardBtn
                    ctaText={'Freeze Subscription'}
                    style={{marginLeft: '15px', marginRight: '15px'}}
                  />
                </div> */}
                {openFreezeModal ? <FreezeModal
                  handleClose={freezeModal}
                  open={openFreezeModal}
                  /> : null}

                <div onClick={confirmCancel}>
                  <DashboardBtn ctaText={'Cancel Subscription'}/>
                </div>
                {openConfirmModal ? <ConfirmModal
                  handleClose={confirmCancel}
                  updateSubDashboard={updateSubDashboard}
                  open={openConfirmModal}
                  /> : null}
              </div>
              <div id='order-total'>Order Total: ${userData.totalPrice}</div>
            </div>
        </div>
        </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
