import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';

// Stripe
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

// MUI Styles
import Alert from '@material-ui/lab/Alert';

import {env} from '../../config/config.js';
import LrcButton from '../common/LrcButton';
import Progress from '../common/Progress';
import getQty from '../../utility/getQty';
import './checkout.css';

const { host, port } = env;

const style = {
    base: {
      fontSize: '16px',
      color: 'black',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
}

const PaymentForm = (props) => {
  const [orderItems, setOrderItems] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  // const options = useOptions();


  useEffect(() => {
    let items = _.get(props, 'location.state.roastItems');
    if (!items) {
      const subscriptionChoice = localStorage.getItem('subscriptionChoice');
      if (subscriptionChoice === 'Blends') {
        items = JSON.parse(localStorage.getItem('orderChoice1'));
      } else if (subscriptionChoice === 'Single Origin') {
        items = JSON.parse(localStorage.getItem('orderChoice3'));
      } else {
        items = JSON.parse(localStorage.getItem('orderChoice2'));
      }
    }
    setOrderItems(items);
    // eslint-disable-next-line
  }, []);

  const displayError = (error) => {
    setIsError(true);
    setErrorMessage(error.message)
    setIsProcessing(false);
  }

  const onSubscriptionComplete = async (result) => {
    const isRetry = _.get(result, 'isRetry');
    result = isRetry ? _.get(result, 'invoice') : result;
    const paymentStatus = _.get(result, 'data.latest_invoice.payment_intent.status');
    if (paymentStatus === 'succeeded') {
      const active = _.get(result, 'data.status') === 'active';
      if (active) {
        setIsActive(true);
        setIsProcessing(false);
        // redirect to success page
      }
    } else if (paymentStatus ==='requires_payment_method') {
      localStorage.setItem('latestInvoiceId', _.get(result, 'data.latest_invoice.id'));
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        _.get(result, 'latest_invoice.payment_intent.status')
      );
      // eslint-disable-next-line
      throw { message: 'Your card was declined.' };
    }
  }

  const createSubscription = async ({customerId, paymentMethodId, priceId}) => {
    try {
      const result = await axios.post(`${host}:${port}/api/save_subscription`, {
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
        qty: getQty(orderItems),
        items: orderItems,
        firstName: localStorage.getItem('firstName'),
        subscriptionChoice: localStorage.getItem('subscriptionChoice'),
      })

      if (result.error) {
        throw result.error;
      } else {
        await onSubscriptionComplete(result);
      }
    } catch (e) {
      displayError(e);
    }
    return _.noop;
  }

  const retryInvoiceWithNewPaymentMethod = async ({
    customerId,
    paymentMethodId,
    invoiceId,
    priceId
    }) => {
      try {
        const result = await axios.post(`${host}:${port}/api/retry_invoice`, {
          customerId: customerId,
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId,
        });
        if (result.error) {
          throw result.error;
        } else {
          // complete the result
          await onSubscriptionComplete({
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            isRetry: true,
          });
        }
      } catch (e){
        displayError(e);
      };
  }

  const createPaymentMethod = async (cardElement, customerId, priceId) => {
    try {
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      })

      if (result.error) {
        throw result.error;
      } else {
        setIsError(false);
        const latestInvoiceId = localStorage.getItem('latestInvoiceId');
        const latestInvoicePaymentIntentStatus = localStorage.getItem('latestInvoicePaymentIntentStatus');
        if (latestInvoicePaymentIntentStatus) {
          retryInvoiceWithNewPaymentMethod({customerId,
            paymentMethodId: _.get(result, 'paymentMethod.id'),
            latestInvoiceId,
            priceId})
          // clean up localstorage
          localStorage.removeItem('latestInvoiceId');
          localStorage.removeItem('latestInvoicePaymentIntentStatus');
        } else {
          createSubscription({
            customerId,
            paymentMethodId: _.get(result, 'paymentMethod.id'),
            priceId,
          });
        }
      }
    } catch (e) {
      displayError(e)
    }
  }

  const handleSubmit = async event => {
    event.preventDefault();
    setIsProcessing(true);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const customerId = JSON.parse(localStorage.getItem('customerId')); // TODO: change to sessionStorage
    // check that customerId has an address before continuing
    try {
      const customerAddressEntered = await axios.post(`${host}:${port}/api/address`, {customerId});

      if (_.get(customerAddressEntered, 'data.isCustomerValid')) {
        const cardElement = elements.getElement(CardElement);
        await createPaymentMethod(cardElement, customerId);

      } else {
        displayError({errorMessage: 'Customer address missing'});
      }
    } catch (e) {
      displayError(e);
    }
  };

  if (isActive) {
    return <Redirect to={'/confirm'} />;
  }

  return (
    <>
    {isError ?  (
    <div className='error'>
        <Alert severity="error">{errorMessage}</Alert>
    </div>
    ) : null}
      <form onSubmit={handleSubmit}>
        <CardElement options={{style: style, classes: {base: 'checkout-form'}}}/>
        {isProcessing ? (<Progress />) : 
          (<LrcButton options={{disabled: !stripe, type: 'submit'}} ctaText={'Complete Order'}  />)}
      </form>
    </>
  );
};

export default PaymentForm;