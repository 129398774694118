import React from 'react';
import ListItem from './ListItem';

const CopyL2 = () => {
  return (
    <div className="copy-l2">
      <h2 className="title">Still not sure if a coffee subscription is right for you?</h2>
      <div className="list-items">
        <div className="col-list">
          <div className="col-1">
            <ListItem>Going on vacation? You can pause or cancel at any time</ListItem>
            <ListItem>
              Whether you want the freshest flavor or easy brewing, we offer both whole and ground beans
            </ListItem>
          </div>
          <div className="col-2">
            <ListItem>You’re going to buy coffee anyway, why not something you enjoy drinking?</ListItem>
            <ListItem>Your time is valuable. We curate the best coffees for you.</ListItem>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyL2;
