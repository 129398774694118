import React from 'react';
import _ from 'lodash';
import ItemCard from './ItemCard';

const OrderItems = props => {
  return (
    <div>
      <div className='order-items-header'>
        <h4>Your upcoming order:</h4>
      </div>
      <div className='order-items'>
        {_.map(props.orderItems, (item, key) => (
          <ItemCard item={item} key={key} />
        ))}
      </div>
    </div>
  );
};

export default OrderItems;
