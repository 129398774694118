import React from 'react';

const ComingSoon = () => {
  return (
    <div className='main-container'>
      <div className='inner-error-container'>
        <h1>Coming Soon</h1>
        <p>Please hang tight, we're still working to get this page set up</p>
      </div>
    </div>
  );
};

export default ComingSoon;