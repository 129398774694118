import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const ReviewCard = withStyles({
  root: {
    background: '#d7b56e',
    borderRadius: 3,
    border: 0,
    color: 'black',
    height: '100%',
    width: '72vw',
    padding: '0 30px',
  }
})(Card);

const CardText = withStyles({
  root: {
    maxWidth: '68vw',
    textAlign: 'center',
    marginTop: '3%',
    marginBottom: '3%'
  }
})(CardContent);

const ReviewLg = () => {
  return (
    <div className="review-lg">
      <ReviewCard className="r-lg-card">
        <CardText>
          <h3>Consistently great coffee and service</h3>
          <p>
          I have been ordering coffee from Local Roast Club for almost a year, and the love has truly been rekindled.
          Many of the roasts I get are those used by my beloved coffee shops around town.
          However, I’ve definitely branched out and found new favorites to order alongside my usuals. The fact that I don’t have to
          make extra trips to the grocery store just for mediocre coffee is life-changing. I love the convenience of
          having local coffee delivered to my house!
          </p>
          <i><strong>-Katherine P.</strong></i>
        </CardText>
      </ReviewCard>
    </div>
  );
};

export default ReviewLg;
