import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// MUI Styles
import AddBag from './AddBag';

import Total from './Total';
import RoastList from './RoastList';
import usePrevious from '../../customHooks/usePrevious';
import LrcButton from '../common/LrcButton';
import './subscription.css';


const OrderChoices = (props) => {
  let updating;
  if (props.location && props.location.state) {
    (updating = props.location.state.updating);
  }
  const [reviewPage, setReviewPage] = useState('');
  // const [error, setError] = useState(false);
  // const [isValid, setIsValid] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [roastItems, setRoastItems] = useState([
    { roaster: 'Club Choice', roast: '', bean: '', quantity: 1, size: '12 oz', logo: '' },
  ]);
  const [quantity, setQuantity] = useState(1);

 const updateOrderChoices = () => {
    const qty = _.reduce(roastItems, (acc, item) => {
      acc += item.quantity;
      return acc;
    }, 0)
    setQuantity(qty);
  };

  const addItem = () => {
    setRoastItems([
      ...roastItems,
      { roaster: 'Club Choice', roast: '', bean: '', quantity: 1, size: '12 oz', logo: '' },
    ]);
    setHasSubmitted(false);
  };

  const removeItem = (index) => {
    if (roastItems.length > 1) {
      roastItems.splice(index, 1);
      if (index === 0) {
        roastItems[0].size = '12 oz';
      }
      setRoastItems([...roastItems]);
      updateStorage();
      setHasSubmitted(false);
    }
  };

  const getChoiceKey = () => {
    const choice = localStorage.getItem('subscriptionChoice');
    if (choice) {
      const key = choice === 'Blends' ? 1 : choice === 'Mix' ? 2 : 3;
      return key;
    }
    return 2;
  }

  const updateStorage = () => {
    const key = getChoiceKey();
    localStorage.removeItem(`orderChoice${key}`);
    localStorage.setItem(`orderChoice${key}`, JSON.stringify(roastItems));
  };

  const prevRoastItems = usePrevious(roastItems);

  const header = () => {
    const choice = getChoiceKey();
    if (choice === 1) {
      return 'Blends Only Subscription'
    } else if (choice === 2) {
      return 'Blends and Single Origin Mix Subscription'
    } else {
      return 'Single Origin Subscription'
    }
  }

  useEffect(() => {
    const key = getChoiceKey();
    const storedItems = localStorage.getItem(`orderChoice${key}`);
    if (
      roastItems &&
      ((prevRoastItems &&
      roastItems.length !== prevRoastItems.length) || 
      (roastItems.length && !prevRoastItems && !storedItems))
    ) {
      localStorage.setItem(`orderChoice${key}`, JSON.stringify(roastItems));
      // const orderChoice = JSON.parse(localStorage.getItem(`orderChoice${key}`));
    } else if (
      storedItems &&
      JSON.stringify(JSON.parse(storedItems)) !== JSON.stringify(roastItems)
    ) {
      const orderChoice = JSON.parse(storedItems);
      setRoastItems(orderChoice);
    }
    // eslint-disable-next-line
  }, [roastItems]);

  useEffect(() => {
    checkForAllFields();
  })

  const checkForAllFields = () => {
    const isDataValid = _.reduce(roastItems, (acc, item) => {
      if (item.roaster && item.roast && item.bean) {
        if (item.roaster.length && item.roast.length && item.bean.length) {
          return acc && true;
        }
      }
      return acc && false;
    }, true)
    // setIsValid(isDataValid);
    if (isDataValid) {
      setReviewPage('/subscribe/review');
    } else {
      setReviewPage('');
    }
  }

  const handleSubmit = async () => {
    setHasSubmitted(true);
  }

  return (
    <div className='subscription-container'>
      <div className='inner-sub-container'>
      <h1 id='subscription-title'>{header()}</h1>
        <RoastList
          choiceKey={getChoiceKey()}
          roastItems={roastItems}
          customSelection={true}
          checkForAllFields={checkForAllFields}
          removeItem={removeItem}
          hasSubmitted={hasSubmitted}
          updateOrderChoices={updateOrderChoices}
        />
        <div className='interactive'>
        <div className='plus'>
          <AddBag addItem={addItem} />
        </div>
        <div className='navigate-btn'>
          <div className="button-spacing">
            <Link to={{pathname: '/subscribe', state: {updating}}}>
              <LrcButton ctaText={'Back'}/>
            </Link>
            <Link onClick={handleSubmit} to={{pathname: reviewPage, state: {roastItems, updating, quantity}}}>
              <LrcButton ctaText={'Finish'}/>
            </Link>
          </div>
          <Total orderItems={roastItems} quantity={quantity} />
        </div>
        </div>
      </div>
    </div>
  );
};

export default OrderChoices;


// add a button at the bottom to finish
// if user is logged in make a request to save to the order to the database
// go to payment/review page (TODO: compile the next page to have both the final cart and payment options)
// funnel them forward the dashboard page (this grabs from the database/ stripe - mechanism tbd)