import React, {useState, useEffect, useContext} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

import UpdateAddress from '../address/UpdateAddress';

import {env} from '../../config/config.js';
import { AuthContext } from '../../contexts/AuthContext';

const { host, port } = env;

const UpdateShipping = (props) => {
  const { dispatch, state } = useContext(AuthContext);

  // eslint-disable-next-line
  const [isAddressSaved, setIsAddressSaved] = useState(false);
  const [isAccessDenied, setIsAccessDenied] = useState(false);

  useEffect(() => {
    const checkAuthorization = async () => {
      const {isAuthenticated} = state;
      // set is authorized at the top of this
      // if user is authorized, then hit an endpoint to get their dashboard data
      if (isAuthenticated) {
        if (isAccessDenied) setIsAccessDenied(false);
      } else {
        try {
          const jwt = localStorage.getItem('token');
          await axios.get(`${host}:${port}/api/dashboard`, {headers: {'authorization': jwt}});
          if (isAccessDenied) setIsAccessDenied(false);
          await dispatch({
            type: 'AUTHORIZE'
          });
        } catch (e) {
          // if token is expired see if refreshToken works
          await checkRefreshToken();
        }
      }
    }
    checkAuthorization()
    // eslint-disable-next-line
  }, [isAccessDenied])

  const checkRefreshToken = async () => {
    try {
      const refreshJWT = localStorage.getItem('rfToken');
      const resp = await axios.post(`${host}:${port}/api/login/token`, {token: refreshJWT});
      const token = resp.data.accessToken;
      await dispatch({
        type: 'REFRESH',
        payload: { token }
      });
      if (isAccessDenied) setIsAccessDenied(false);
      await dispatch({
        type: 'AUTHORIZE'
      });
    } catch (err) {
      // redirect to login
      setIsAccessDenied(true);
    }
  }

  useEffect(() => {
    if (isAddressSaved) {
      props.toggleUpdateAddress();
    }
  },
  [isAddressSaved])

  if (isAccessDenied) {
    sessionStorage.setItem('continueToPayment', true)
    return <Redirect to={'/login'} />;
  }

  return (
    <>
      <UpdateAddress setIsAddressSavedParent={setIsAddressSaved} fromAccountPage={true} />
    </>
  );
};

export default UpdateShipping;