import React, {useEffect, useState, useContext} from 'react';
import axios from 'axios';
import _ from 'lodash';
import {NavLink, Redirect} from 'react-router-dom';

// MUI Styles
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import {AuthContext} from '../../contexts/AuthContext';
import {env} from '../../config/config.js';
import './signup.css';

const {host, port} = env;

const SignupBtn = withStyles({
  root: {
    background: '#f18f36',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(90, 90, 27, 0.3)',
    color: 'black',
    height: 48,
    width: '10vh',
    padding: '10px 30px',
    marginBottom: '10%',
  },
})(Button);

const Signup = (props) => {
  const {dispatch} = useContext(AuthContext);

  const initialState = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [data, setData] = useState(initialState);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkAuthorization = async () => {
      try {
        const jwt = localStorage.getItem('token');
        await axios.get(`${host}:${port}/api/login/authenticate`, {headers: {authorization: jwt}});
        setIsLoggedIn(true);
        await dispatch({
          type: 'AUTHORIZE',
        });
      } catch (e) {
        // try looking for refreshToken
        try {
          const refreshJWT = JSON.parse(localStorage.getItem('rfToken'));
          const resp = await axios.post(`${host}:${port}/api/login/token`, {token: refreshJWT});
          const token = resp.data.accessToken;
          await dispatch({
            type: 'REFRESH',
            payload: {token},
          });
          setIsLoggedIn(true);
          await dispatch({
            type: 'AUTHORIZE',
          });
        } catch (e) {
          // redirect to login
          setIsLoggedIn(false);
        }
      }
    };
    checkAuthorization();
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const validateSubmission = (data) => {
    const {name, email, password, confirmPassword} = data;
    const isBlankField = _.reduce(
      data,
      (acc, value, key) => {
        if (!value.length) {
          return false;
        }
        return acc && true;
      },
      true
    );

    if (!isBlankField) {
      setErrorMessage(['Field is required']);
      return false;
    }
    if (!email.includes('@')) {
      setErrorMessage(['Not a valid email']);
      return false;
    }
    if (password !== confirmPassword) {
      setErrorMessage(["Passwords don't match"]);
      return false;
    }
    if (name.length > 30 || email.length > 255 || password.length > 40 || confirmPassword.length > 40) {
      setErrorMessage(['Please fix your entry and try again']);
      return false;
    }
    return true;
  };

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setData({
      ...data,
    });
    setIsSubmitting(true);
    const isValid = validateSubmission(data);
    if (!isValid) {
      setIsSubmitting(false);
      return null;
    }
    axios
      .post(`${host}:${port}/api/register/`, {
        name: data.name,
        email: data.email,
        password1: data.password,
        password2: data.confirmPassword,
      })
      .then((res) => {
        setData({
          ...data,
        });
        setIsSubmitting(false);
        return res.data;
      })
      .then((result) => {
        dispatch({
          type: 'LOGIN',
          payload: {
            user: data.email,
            token: result.accessToken,
            refreshToken: result.refreshToken,
            customerId: result.customerId,
          },
        });
      })
      .then(() => {
        setIsLoggedIn(true);
      })
      .catch((err) => {
        const errorMsg = [];
        if (typeof _.get(err, 'response.data.errors') === 'object') {
          _.forEach(err.response.data, (e) => {
            errorMsg.push(e[0].msg);
          });
        } else {
          errorMsg.push('Ooops something went wrong');
        }
        setIsSubmitting(false);
        setErrorMessage(errorMsg);
      });
  };

  useEffect(() => {
    // if error message
  }, [errorMessage]);

  const referer = _.get(props, 'location.state.referer') || '/dashboard';

  if (isLoggedIn) {
    if (sessionStorage.getItem('continueToPayment') === 'true') {
      sessionStorage.removeItem('continueToPayment');
      return <Redirect to={'/payment'} />;
    }
    return <Redirect to={referer} />;
  }

  return (
    <div className="signup-container">
      <div className="inner-container">
        <h1>Sign Up</h1>
        {errorMessage
          ? _.map(errorMessage, (msg, index) => {
              return (
                <div className="error" key={index}>
                  <Alert severity="error">{msg}</Alert>
                </div>
              );
            })
          : null}
        <form
          className="signup-form"
          name="signup-form"
          noValidate
          autoComplete="on"
          method="POST"
          onSubmit={handleFormSubmit}>
          <TextField
            required
            id="outlined-name"
            label="name"
            name="name"
            value={data.name}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
          />
          <TextField
            required
            id="outlined-email-input"
            label="Email"
            type="email"
            name="email"
            value={data.email}
            onChange={handleInputChange}
            autoComplete="email"
            margin="normal"
            variant="outlined"
          />
          <TextField
            required
            id="outlined-password-input"
            label="Password"
            type="password"
            name="password"
            value={data.password}
            onChange={handleInputChange}
            autoComplete="current-password"
            margin="normal"
            variant="outlined"
          />
          <TextField
            required
            id="outlined-password-verify-input"
            label="Confirm Password"
            type="password"
            name="confirmPassword"
            value={data.confirmPassword}
            onChange={handleInputChange}
            autoComplete="current-password"
            margin="normal"
            variant="outlined"
          />
          <SignupBtn type="submit" value="Submit">
            {isSubmitting ? 'Submitting...' : 'Signup'}
          </SignupBtn>
          <div className="log-in-txt">
            Already have an account?
            <NavLink style={{marginRight: '10px'}} to="/login">
              {' '}
              Login
            </NavLink>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
