import React from 'react';
import _ from 'lodash';
import {NavLink} from 'react-router-dom';

import './reset.css';

const ResetSuccess = (props) => {
  return (
    <div className="reset-container">
      <div className="inner-container-nm">
        <p>Password updated successfully --</p>
        <div>
          <NavLink style={{marginRight: '10px'}} to="/login">
            Login
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ResetSuccess;
