import React from 'react';
import _ from 'lodash';
import ItemCard from '../product/ItemCard';

const OrderList = props => {

  const renderSubscriptionChoiceText = () => {
    const subscriptionChoice = localStorage.getItem('subscriptionChoice');
    if (subscriptionChoice === 'Blends') {
      return 'Blends Only Subscription';
    } else if (subscriptionChoice === 'Mix') {
      return 'Blends and Single Origin Mix Subscription';
    } else if (subscriptionChoice === 'Single Origin') {
      return 'Single Origin Subscription';
    }
  }

  return (
    <div className="review-content">
      <div className="review-subscription-choice">{renderSubscriptionChoiceText()}</div>
      {_.map(props.orderItems, (item, key) => (
        <ItemCard item={item} selection={props.selection} key={key} />
      ))}
    </div>
  );
};

export default OrderList;
