import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  leftCardHead: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  btn: {
    background: '#F18F36',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.54)',
    color: 'black',
    height: 48,
    padding: '0 30px',
    '&:hover': {
      backgroundColor: '#d7b56d69'
    }
  }
}));

const HeaderLeft = () => {
  const classes = useStyles();

  return (
    <div className="header-box">
      <div className='header-text'>
        <h1 id='headline'>Discover gourmet coffee in the comfort of your home.</h1>
        <h4 id='headline-2'>Now your new favorite coffee is just a click away.</h4>
        <Link to='/subscribe'>
          <Button className={classes.btn}>Get Started</Button>
        </Link>
      </div>
    </div>
  );
};

export default HeaderLeft;
