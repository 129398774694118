import axios from 'axios';
import _, { update } from 'lodash';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Menu from './Menu';

import {env} from '../../config/config.js';
import '../userdashboard/itemcard.css';

const { host, port } = env;

const useStyles = makeStyles((theme) => ({
  card: {
    background: '#fffcfe',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '80vw',
    maxHeight: '130vh',
    marginLeft: 0,
    marginRight: 0,
    margin: 10,
    font: 'CircleMedium, sans-serif',
    [theme.breakpoints.down(840)]: {
      width: '70vw',
      flexDirection: 'column',
      alignItems: 'center',
    },
    [theme.breakpoints.down(600)]: {
      // height: '115vh',
      width: '70vw',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  headerSpace: {
    display: 'flex',
    width: '100%',
    background: '#6A7933',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, .5)',
  },
  header: {
    display: 'flex',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down(840)]: {
      width: '60vw',
      flexDirection: 'column',
      alignItems: 'center',
    },
    [theme.breakpoints.down(600)]: {
      minHeight: '70vh',
      width: '70vw',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'left',
    marginLeft: '2%',
    width: '100%',
    [theme.breakpoints.down(840)]: {
      width: '60vw',
      flexDirection: 'column',
      alignItems: 'center',
    },
    [theme.breakpoints.down(600)]: {
      minHeight: '20vh',
      width: '70vw',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  paragraph: {
    display: 'flex',
    justifyItems: 'left',
    [theme.breakpoints.down(940)]: {
      margin: 3,
    },
  },
  image: {
    margin: 10,
    marginRight: 20,
    width: 151,
    height: 151,
    objectFit: 'scale-down',
  },
  root: {
    fontFamily: 'CircleMedium, sans-serif',
    marginBottom: '.2em',
    fontSize: '4vh',
  },
  text: {
    fontFamily: 'CircleMedium, sans-serif',
    fontSize: '2.5vh',
    [theme.breakpoints.down(940)]: {
      fontSize: '2.5vh',
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexFlow: 'wrap',
    marginRight: '2%',
    width: '35vw',
    [theme.breakpoints.down(840)]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '35vw',
    },
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '50vw',
    },
  },
  button: {
    fontFamily: 'CircleMedium, sans-serif',
  },
}));

const roastList = [
  'Light',
  'Medium',
  'Dark',
  'Espresso',
  'Decaf',
  'Club Choice',
];
const beanStatusList = ['Whole', 'Ground'];

const quantityList = [1, 2, 3, 4, 5, 6, 7, 8];

const RoastListItem = (props) => {
  const classes = useStyles();
  const alt = `${props.item.roaster} ${props.item.roastType} roast ${props.item.beanStatus} bean`;
  const customSelection = props.customSelection;
  const [roasterList, setRoasterList] = useState([]);
  const [roasterData, setRoasterData] = useState({});

  const [value, setValue] = useState({
    roaster: props.item.roaster,
    roast: props.item.roast,
    bean: props.item.bean,
    origin: props.item.origin,
    quantity: props.item.quantity,
    size: props.item.size,
    logo: props.item.logo,
  });

  const handleChange = (event) => {
    const property = event.target.name;
    setValue({ ...value, [property]: event.target.value });
    if (property === 'roaster') {
      setValue({ ...value, logo: roasterData[event.target.value]['logo'] });
      props.item.logo = roasterData[event.target.value]['logo'];
    }
    // check hasAllEntries and toggle is valid in the individual field
    props.item[property] = event.target.value;
    updateStorage();
    props.updateOrderChoices()
  };

  const updateStorage = () => {
    let storageItems = localStorage.getItem(`orderChoice${props.choiceKey}`);

    storageItems = JSON.parse(storageItems);
    storageItems.splice(props.index - 1, 1, value);

    localStorage.removeItem(`orderChoice${props.choiceKey}`);
    localStorage.setItem(`orderChoice${props.choiceKey}`, JSON.stringify(storageItems));
  };

  useEffect(() => {
    axios.get(`${host}:${port}/api/roaster/allRoasters`).then((resp) => {
        const roasterNames = _.map(resp.data, (el) => {
          return el.name;
        })
        roasterNames.push('Club Choice');
        setRoasterList(roasterNames);

        const roasterNamesAndLogos = _.reduce(
          resp.data,
          (acc, el, key) => {
            acc[el.name] = { logo: el.logo_url };
            return acc;
          },
          {}
        );
        roasterNamesAndLogos['Club Choice'] = {name: 'Club Choice', logo: 'https://lrcimages.s3.us-east-2.amazonaws.com/roasterlogos/lrclogo.svg'}
      setRoasterData(roasterNamesAndLogos)
    });
  }, []);

  useEffect(() => {
    setValue(props.item);
  }, [value, props.item]);

  useEffect(() => {
    props.checkForAllFields()
  })

  return (
    <div>
      <Card className={classes.card}>
        <div className={classes.headerSpace}>
          <CardHeader
            className={classes.header}
            title={`Option ${props.index}:`}
          />
          {customSelection ? (
            <Button
              className={classes.button}
              size='medium'
              color='inherit'
              onClick={() => {
                props.removeItem(props.index - 1);
              }}
            >
              X
            </Button>
          ) : null}
        </div>
        <div className={classes.content}>
          <div className={classes.details}>
            <CardMedia
              className={classes.image}
              component='img'
              alt={alt}
              height='140'
              image={
                roasterData[value.roaster]
                  ? roasterData[value.roaster]['logo']
                  : 'https://lrcimages.s3.us-east-2.amazonaws.com/roasterlogos/lrclogo.svg'
              }
              // title={props.item.roaster}
            />
            <CardContent className={classes.paragraph}>
              <div className={classes.text}>
                <p>Roaster: {value.roaster}</p>
                <p>Roast Type: {value.roast}</p>
                <p>Beans: {value.bean}</p>
                <p>Qty: {value.quantity}</p>
                {/* <p>Origin: {value.origin}</p>
                <p>Bag Size: {value.size}</p> */}
              </div>
            </CardContent>
          </div>
          <CardActions className={classes.actions}>
            <Menu
              value={value.roaster}
              handleChange={handleChange}
              name={'roaster'}
              list={roasterList}
              listName={'Roaster'}
              hasSubmitted={props.hasSubmitted}
            />
            <Menu
              value={value.roast}
              handleChange={handleChange}
              name={'roast'}
              list={roastList}
              listName={'Roast'}
              hasSubmitted={props.hasSubmitted}
            />
            <Menu
              value={value.bean}
              handleChange={handleChange}
              name={'bean'}
              list={beanStatusList}
              listName={'Bean Status'}
              hasSubmitted={props.hasSubmitted}
            />
            <Menu
              value={value.quantity}
              handleChange={handleChange}
              name={'quantity'}
              list={quantityList}
              listName={'Qty'}
              hasSubmitted={props.hasSubmitted}
            />
          </CardActions>
        </div>
      </Card>
    </div>
  );
};

export default RoastListItem;
