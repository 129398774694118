import React, {useContext, useEffect} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// MUI styles/components
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import LoginButton from './LoginButton';
import MenuDropdown from './MenuDropdown';
import AccountButton from './AccountButton';
import { ReactComponent as LrcLogo } from '../lrclogo.svg';

import { AuthContext } from '../contexts/AuthContext';
import {env} from '../config/config.js';
const { host, port } = env;


const useStyles = makeStyles((theme) => ({
  root: {
    background: 'black',
    height: '16vh',
    justifyContent: 'center',
    flexGrow: 1,
  },
  menuButton: {
    display: 'none',
    marginLeft: -12,
    marginRight: 20,
    [theme.breakpoints.down(768)]: {
      display: 'inline',
    },
  },
  grow: {
    // flexGrow: 1
  },
  links: {
    margin: '2vw',
    flexGrow: 2,
  },
  item: {
    marginLeft: '1.25vw',
    marginRight: '1.25vw',
    '& a' : {
      color: 'white',
      '&:hover': {
        color: '#f18f36'
      }
    },
    [theme.breakpoints.down(768)]: {
      display: 'none',
    },
  },
  logo: {
    height: '100px',
    width: '100px',
    flexGrow: 1,
  },
}));

function NavBar() {
  const { dispatch } = useContext(AuthContext);

  const classes = useStyles();

  useEffect(() => {
    const checkAuthorization = async () => {
      // set is authorized at the top of this
      // if user is authorized, then hit an endpoint to get their dashboard data
      try {
        const jwt = localStorage.getItem('token');
        await axios.get(`${host}:${port}/api/dashboard`, {headers: {'authorization': jwt}});
        await dispatch({
          type: 'AUTHORIZE'
        });
      } catch (e) {
        // try looking for refreshToken
        await checkRefreshToken();
      }
    }
    checkAuthorization()
    // eslint-disable-next-line
  }, [])

  const checkRefreshToken = async () => {
    try {
      // we want to know if accessIsDenied
      // if yes, try using a new token to generate a refresh token
      const refreshJWT = localStorage.getItem('rfToken');
      const resp = await axios.post(`${host}:${port}/api/login/token`, {token: refreshJWT});
      const token = resp.data.accessToken;
      await dispatch({
        type: 'REFRESH',
        payload: { token }
      });
      await dispatch({
        type: 'AUTHORIZE'
      });
    } catch (err) {
      // should we dispatch an unauthorized? I think it's default sooo??
    }
  }


  return (
    <div className='nav'>
      <AppBar position='static' className={classes.root}>
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            color='inherit'
            aria-label='Menu'
          >
            <MenuDropdown />
          </IconButton>
          <div className={classes.grow}>
            <Link to='/'>
              <LrcLogo className={classes.logo} alt='Local Roast Club Logo' />
            </Link>
          </div>
          <Typography variant='h6' color='inherit' className={classes.links}>
            <span className={classes.item}>
              <Link to='/'>Home</Link>
            </span>
            <span className={classes.item}>
              <Link to='/subscribe'>Subscribe</Link>
            </span>
            <span className={classes.item}>
              <Link to='/roasters'>Roasters</Link>
            </span>
            <span className={classes.item}>
              <Link to='/blog'>Blog</Link>
            </span>
            {/* <span className={classes.item}>
              <Link to='/shop'>Shop</Link>
            </span> */}
          </Typography>
          <AccountButton />
          <LoginButton/>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default NavBar;
