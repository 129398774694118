//add all the components from ./components/sales here

import React from 'react';

import Content from '../components/landing/Content'


const Landing = () => {
  return (
    <>
      <Content />
    </>
  )
}

export default Landing;