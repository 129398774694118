import React from 'react';
import _ from 'lodash';

import './reset.css';

const NoMatch = (props) => {
  return (
    <div className="reset-container">
      <div className="inner-container-nm">
        <p>Password cannot be updated at this time</p>
      </div>
    </div>
  );
};

export default NoMatch;
