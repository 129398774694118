import React from 'react';
import _ from 'lodash';
import MediaCard from './MediaCard';

const copyList = [
  {
    name: 'register',
    header: 'Step 1',
    copy: 'Sign up and select your preference of Roast, Bean, and Roaster'
  },
  {
    name: 'roasted',
    header: 'Step 2',
    copy: 'Beans are roasted to order and shipped on the 1st Monday of each month'
  },
  {
    name: 'delivery',
    header: 'Step 3',
    copy: 'Fresh premium coffee is delivered to your door once every month'
  }
];

const HowItWorks = () => {
  return (
    <div className='how-container'>
      <h2>How it works</h2>
      <div className='how-it-works'>
        {_.map(copyList, (item, key) => {
          return <MediaCard item={item} rowName={'how'} key={key} />;
        })}
      </div>
    </div>
  );
};

export default HowItWorks;
