import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import currency from '../../utility/currency';

const useStyles = makeStyles({
  card: {
    width: 345,
    minWidth: 245,
    height: 350,
    margin: '3vw'
  },
  media: {
    height: 140
  },
  description: {
    height: 80
  },
  price: {
    display: 'flex',
    justifyContent: 'center'
  }
});

const SubscriptionCard = props => {
  const classes = useStyles();
  const {updating} = props;

  const handleSubscriptionType = (event) => {
    const subType = event.target.getAttribute('name');
    localStorage.setItem('subscriptionChoice', subType);
  };


  return (
    <Link to={{pathname: '/subscribe/choice', state: {updating}}}>
      <Card
        className={classes.card}
        name={props.item.name}
        onClick={handleSubscriptionType}
      >
        <CardActionArea name={props.item.name}>
          <CardMedia
            className={classes.media}
            name={props.item.name}
            image={props.item.image}
          />
          <CardContent name={props.item.name}>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              name={props.item.name}
            >
              {props.item.name}
            </Typography>
            <Typography
              className={classes.description}
              variant="body2"
              color="textSecondary"
              component="p"
              name={props.item.name}
            >
              {props.item.description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions
          className={classes.price}
        >
          <Typography
            variant="subtitle1"
          >
            Starting at: {currency(props.item.price)} / bag
          </Typography>
        </CardActions>
      </Card>
    </Link>
  );
};

export default SubscriptionCard;
