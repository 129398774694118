import React from 'react';
import RoasterDisplay from './RoasterDisplay';

const RoasterPage = (props) => {
  const {logo, description, name} = props.location.state;

  const renderText = (description) => {
    return description.split('\n').map((paragraph, i) => {
      return (
        <p key={i} style={{marginBottom: '3vh'}}>
          {paragraph}
        </p>
      );
    });
  };
  return (
    <div className="outer-container">
      <div className="inner-container">
        <div style={{maxWidth: '80vw', maxHeight: '30vh'}}>
          <img name={name} src={logo} alt={`${name} logo`} style={{maxWidth: '100%', maxHeight: '100%'}} />
        </div>
        <div className="paragraph-content">
          <div className="paragraph">{renderText(description)}</div>
        </div>
        <RoasterDisplay />
      </div>
    </div>
  );
};

export default RoasterPage;
