import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import variety from '../../svg/variety.svg';
import traffic from '../../svg/traffic.svg';
import local from '../../svg/local.svg';
import register from '../../svg/register.svg';
import roasted from '../../svg/roasted.svg';
import delivery from '../../svg/delivery.svg';


const useStyles = makeStyles({
  card: {
    maxWidth: 345,
    marginTop: '2%',
    marginBottom: '2%',
    boxShadow: 'none'
  },
  actionArea: {
    "&:hover $focusHighlight": {
      opacity: 0,
      cursor: 'auto',
    },
    '&$focusVisible $focusHighlight': {
      opacity: 0,
    },
    backgroundColor: 'white',
    boxShadow: 'none'
  },
  focusHighlight: {
    transition: 'none',
  },
  media: {
    display: 'flex',
    justifyContent: 'center',
    height: 240
  },
  cardText: {
    height: '100px'
  }
});

const renderImage = (item) => {
  const {name, alt} = item.item
  const imageDict = {
    variety,
    traffic,
    local,
    register,
    roasted,
    delivery,
  }
  return <img src={imageDict[name]} style={{width: '100%'}} alt={alt} />
}

export default function MediaCard(props) {
  const classes = useStyles();

  const rowName = props.rowName === 'subheader' ? 'cardCopy' : 'howCards';

  return (
    <Card
      className={`${classes.card} ${
        rowName === 'cardCopy' ? classes.cardCopy : classes.howCards
      }`}
    >
        <CardMedia
          className={classes.media}
          image={props.item.image}
          title={props.item.alt}
        >
          {renderImage(props)}
        </CardMedia>
        <CardContent className={classes.cardText}>
          <Typography gutterBottom variant="h5" component="h2">
            {props.item.copy}
          </Typography>
        </CardContent>
    </Card>
  );
}
