import React from 'react';
import RoasterDisplay from './RoasterDisplay';
// 

const Roasters = () => {
  return (
    <div className="outer-container">
    <div className="inner-container">
      <RoasterDisplay />
    </div>
  </div>
  );
};

export default Roasters;