import React, {useEffect, useState} from 'react';
import axios from 'axios';
import _ from 'lodash';
import {Redirect} from 'react-router-dom';

// MUI Styles
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import {env} from '../../config/config.js';
import './reset.css';

const {host, port} = env;

const SignupBtn = withStyles({
  root: {
    background: '#f18f36',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(90, 90, 27, 0.3)',
    color: 'black',
    height: 48,
    width: '10vh',
    padding: '10px 30px',
    marginBottom: '10%',
  },
})(Button);

const ResetPassword = (props) => {
  const initialState = {
    password: '',
    confirmPassword: '',
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [data, setData] = useState(initialState);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const checkToken = async () => {
      try {
        const resetPasswordToken = window.location.pathname;
        const resetResp = await axios.get(`${host}:${port}/api${resetPasswordToken}`);
        if (_.get(resetResp, 'data.status') === 200) {
          setIsTokenValid(true);
        }
      } catch (e) {
        setIsTokenValid(false);
      }
    };
    checkToken();
  }, []);

  const validateSubmission = (data) => {
    const {password, confirmPassword} = data;
    const isBlankField = _.reduce(
      data,
      (acc, value, key) => {
        if (!value.length) {
          return false;
        }
        return acc && true;
      },
      true
    );
    if (!isBlankField) {
      setErrorMessage(['Field is required']);
      return false;
    }
    if (password !== confirmPassword) {
      setErrorMessage(["Passwords don't match"]);
      return false;
    }
    if (password.length > 40 || confirmPassword.length > 40) {
      setErrorMessage(['Please fix your entry and try again']);
      return false;
    }
    return true;
  };

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setData({...data});
    setIsSubmitting(true);
    const isValid = validateSubmission(data);
    if (!isValid) {
      setIsSubmitting(false);
      return null;
    }
    try {
      const token = window.location.pathname;
      const resetResp = await axios.post(`${host}:${port}/api${token}`, {
        password1: data.password,
        password2: data.confirmPassword,
      });
      setData({...data});
      setIsSubmitting(false);
      setIsSuccess(true);
    } catch (err) {
      const errorMsg = [];
      if (typeof _.get(err, 'response.data.errors') === 'object') {
        _.forEach(err.response.data, (e) => {
          errorMsg.push(e[0].msg);
        });
      } else {
        errorMsg.push('Ooops something went wrong');
      }
      setIsSubmitting(false);
      setErrorMessage(errorMsg);
    }
  };

  useEffect(() => {
    // if error message
  }, [errorMessage]);

  if (isSuccess) {
    return <Redirect to={{pathname: '/success'}} />;
  }
  if (!isTokenValid) {
    return <Redirect to={{pathname: '/no_match', state: {from: props.location}}} />;
  }

  return (
    <div className="reset-container">
      <div className="inner-container">
        <h1>Reset Password</h1>
        {errorMessage
          ? _.map(errorMessage, (msg, index) => {
              return (
                <div className="error" key={index}>
                  <Alert severity="error">{msg}</Alert>
                </div>
              );
            })
          : null}
        <form
          className="signup-form"
          name="signup-form"
          noValidate
          autoComplete="on"
          method="POST"
          onSubmit={handleFormSubmit}>
          <TextField
            required
            id="outlined-password-input"
            label="Password"
            type="password"
            name="password"
            value={data.password}
            onChange={handleInputChange}
            autoComplete="current-password"
            margin="normal"
            variant="outlined"
          />
          <TextField
            required
            id="outlined-password-verify-input"
            label="Confirm Password"
            type="password"
            name="confirmPassword"
            value={data.confirmPassword}
            onChange={handleInputChange}
            autoComplete="current-password"
            margin="normal"
            variant="outlined"
          />
          <SignupBtn type="submit" value="Submit">
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </SignupBtn>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
