import React, {useState} from 'react';
import _ from 'lodash';
import axios from 'axios';

// MUI styles
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';

import LrcButton from '../common/LrcButton';

import {env} from '../../config/config.js';

const {host, port} = env;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '60vw',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  form: {
    width: '45vw',
  },
  submitButton: {
    display: 'flex',
    width: '45vw',
    justifyContent: 'flexEnd',
  },
}));

const ContactUs = () => {
  const classes = useStyles();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formError, setFormError] = useState({});
  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleFormUpdate = (event) => {
    const property = event.target.name;
    const isNotValidEntry = validateFormData(event);
    if (isNotValidEntry) {
      // display an error and block typing
    } else {
      setContactForm({...contactForm, [property]: event.target.value});
    }
  };

  const validateFormData = (event) => {
    const property = event.target.name;
    const value = event.target.value;
    const elValue = event.currentTarget.value;
    const isTextOnly = property === 'name';
    if (value.match(/[0-9]/) && isTextOnly) {
      return true;
    }
    if (property === 'name' && elValue.length > 35) {
      return true;
    }
    if (property === 'email' && elValue.length > 321) {
      return true;
    }
    if (property === 'message' && elValue.length > 255) {
      return true;
    }
  };

  function isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const validateSubmission = (data) => {
    const {email} = data;

    const isBlankField = _.reduce(
      data,
      (acc, value, key) => {
        return acc && true;
      },
      true
    );

    if (!isBlankField) {
      setFormError({...formError, isError: true, errorMsg: 'Field is required'});
      return false;
    }
    if (!email.includes('@')) {
      setFormError({...formError, isError: true, errorMsg: 'Not a valid email'});
      return false;
    }
    if (!isValidEmail(email)) {
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const isValid = validateSubmission(contactForm);
    if (!isValid) {
      setIsSubmitting(false);
      return null;
    }
    try {
      const contactResp = await axios.post(`${host}:${port}/api/contact`, contactForm);
      setIsSubmitting(false);
      if (contactResp.status === 200) {
        setIsSuccess(true);
      } else {
        const errorMsg = [];
        errorMsg.push('Something went wrong, message not sent');
        setErrorMessage(errorMsg);
      }
    } catch (err) {
      const errorMsg = [];
      if (typeof _.get(err, 'response.data.errors') === 'object') {
        _.forEach(err.response.data, (e) => {
          errorMsg.push(e[0].msg);
        });
      } else {
        errorMsg.push('Something went wrong, message not sent');
      }
      setIsSubmitting(false);
      setErrorMessage(errorMsg);
    }
    // TODO ref contact-form
  };

  return (
    <div className="outer-container">
      <div className="inner-container">
        <h1>Contact Us</h1>
        {errorMessage
          ? _.map(errorMessage, (msg, index) => {
              return (
                <div className="error" key={index}>
                  <Alert severity="error">{msg}</Alert>
                </div>
              );
            })
          : null}
        {formError.isError ? <Alert severity="error">{formError.errorMsg}</Alert> : null}
        {isSuccess ? (
          <Alert severity="success">Message sent successfully!</Alert>
        ) : (
          <>
            <div>
              <form className={classes.root} noValidate autoComplete="off">
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  variant="outlined"
                  className={classes.form}
                  onChange={handleFormUpdate}
                />
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                  className={classes.form}
                  onChange={handleFormUpdate}
                />
                <TextField
                  id="message"
                  name="message"
                  label="Message"
                  variant="outlined"
                  multiline
                  rows={10}
                  className={classes.form}
                  onChange={handleFormUpdate}
                />
              </form>
            </div>
            <div onClick={handleSubmit} className={classes.submitButton}>
              <LrcButton ctaText={'Send'} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactUs;
