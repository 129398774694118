import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

import {env} from '../../config/config.js';
const { host, port } = env;

const useStyles = makeStyles((theme) => ({
  imageGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  image: {
    margin: 10,
    // marginRight: 20,
    width: 180,
    height: 180,
    objectFit: 'scale-down',
  }
}));

const RoasterDisplay = () => {
  const classes = useStyles();

  // const [roasterList, setRoasterList] = useState([]);
  const [roasterData, setRoasterData] = useState({});

  useEffect(() => {
      axios.get(`${host}:${port}/api/roaster/allRoasters`).then((resp) => {
        setRoasterData(
          _.reduce(
            resp.data,
            (acc, el, key) => {
              acc[el.name] = { logo: el.logo_url, name: el.name, key, description: el.description };
              return acc;
            },
            {}
          )
        );
      }).catch(error => {
        // do we want to do anything if this fails?
      });
  }, []);

  return (
    <div className={classes.imageGrid}>
        {_.map(roasterData, (tile) => {
          const key = tile.key;
          return (
            <Link to={{pathname: `roaster/${_.get(tile, 'name')}`, state: tile}}>
              <img className={classes.image} name={_.get(tile, 'name')} src={_.get(tile, 'logo')} alt={_.get(tile, 'name')} key={key} />
            </Link>
          )
          })}
    </div>
  );
};

export default RoasterDisplay;