import React, {useState, useEffect, useContext} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { AuthContext } from '../contexts/AuthContext';
import {env} from '../config/config.js';

const { host, port } = env;

const Footer = () => {
  const { dispatch, state } = useContext(AuthContext);
  const {isAuthenticated} = state;

  const [isLoggedOut, setIsLoggedOut] = useState(!isAuthenticated);

  useEffect(() => {
    const checkAuthorization = async () => {
      // set is authorized at the top of this
      // if user is authorized, then hit an endpoint to get their dashboard data
      const jwt = localStorage.getItem('token');
      if (isAuthenticated) {
        setIsLoggedOut(false);
      }
      if (!isAuthenticated && jwt) {
        try {
          await axios.get(`${host}:${port}/api/payment`, {headers: {'authorization': jwt}});
          // send our jwt token in the header
          // we want to know if accessIsDenied
          // if yes, try using a new token to generate a refresh token
          if (isLoggedOut) setIsLoggedOut(false);
        } catch (e) {
          // try looking for refreshToken
          try {
            // we want to know if accessIsDenied
            // if yes, try using a new token to generate a refresh token
            const refreshJWT = localStorage.getItem('rfToken');
            const resp = await axios.post(`${host}:${port}/api/login/token`, {token: refreshJWT});
            const token = resp.data.accessToken;
            await dispatch({
              type: 'REFRESH',
              payload: { token }
            });
            if (isLoggedOut) setIsLoggedOut(false);
          } catch (e) {
            // redirect to login
            setIsLoggedOut(true);
          }
        }
      }
    }
    checkAuthorization()
    // eslint-disable-next-line
  }, [isLoggedOut])


  return (
    <div className="footer">
      <div className="footer-nav">
        <div className="footer-col-1">
          <Link to={{pathname: isAuthenticated ? '/account' : '/login'}}>
            <div className="footer-item">My Account</div>
          </Link>
          <Link to={{pathname: isAuthenticated ? '/dashboard' : '/login'}}>
            <div className="footer-item">My Order</div>
          </Link>
        </div>
        <div className="footer-col-2">
          <Link to={'/contact'}>
            <div className="footer-item">Contact Us</div>
          </Link>
          <Link to={'/returns'}>
            <div className="footer-item">Returns</div>
          </Link>
          <Link to={'/about'}>
            <div className="footer-item">About Us</div>
          </Link>
        </div>
        <div className="footer-col-3">
          <a
            href="https://www.facebook.com/Localroastclub/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="icon icon-fb" />
          </a>
          <a
            href="https://twitter.com/localroastclub/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="icon icon-tweet" />
          </a>
          <a
            href="https://www.instagram.com/local_roast_club/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="icon icon-insta" />
          </a>
        </div>
      </div>
      <div className="footer-info">
        <p className="footer-cr">
          © Copyright 2021 Local Roast Club. All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
