import React from 'react';
import ReviewCarousel from './ReviewCarousel';
import './carousel.css';

const carouselSlidesData = [
  {
    title: 'Love Local Roast Club!',
    content:
      'LRC makes it much easier in a major part of my everyday routine to support some local businesses. Especially right now in the year 2020, when convenience is everything and we\'re almost all confined to our homes, it\'s everything I could want. 1000 chef\'s kisses!',
    author: 'Cate',
    avatar: 'facebook',
    location: 'Austin, Tx'
  },
  {
    title: 'Decafs from all around',
    content:
      "I'm only drinking decaf these days, so it's nice to be able to try decaf from roasters all over town!",
    author: 'Meredith',
    avatar: 'Snapchat',
    location: 'Austin, Tx'
  },
  {
    title: 'Convenient and quality!',
    content:
      'I was interested in buying local coffee but I never had time to go to actual coffee shops all the time when I ran out of beans. This service is convenient and quality with an emphasis on the local peeps. I would highly reccomend trying it out if you like good coffee at home!',
    author: 'Sam',
    avatar: 'facebook',
    location: 'Fort Worth, Tx'
  }
];

const Reviews = () => {
  return (
    <div className="paragraph-3">
      <h2 className="title">What Local Roast Club members say:</h2>
      <div className="carousel-container">
        <ReviewCarousel slides={carouselSlidesData} />
      </div>
    </div>
  );
};

export default Reviews;
