import React, {useContext, useState} from 'react';
import axios from 'axios';
import _ from 'lodash';
import { Link, Redirect } from 'react-router-dom';

import { AuthContext } from '../contexts/AuthContext';
import {env} from '../config/config.js';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';


const { host, port } = env;

const useStyles = makeStyles((theme) => ({
  loginBtn: {
    [theme.breakpoints.down(768)]: {
      display: 'none',
    },
  },
}));

const LoginBtn = withStyles({
  root: {
    color: '#ffffff',
    fontFamily: 'CircleMedium, sans-serif',
    fontSize: 16,
    textDecoration: 'none',
  },
})(Button);

const LoginButton = (props) => {
  const { dispatch } = useContext(AuthContext);
  let isAuthenticated = _.get(useContext(AuthContext), 'state.isAuthenticated');
  
  const classes = useStyles();
  const [didLogOut, setDidLogOut] = useState(false);

  const handleLogout = (event) => {
    event.preventDefault();
    axios.delete(`${host}:${port}/api/logout/`, {headers: {authorization: localStorage.getItem('rfToken')}})/* {headers: {'authorization': jwt}} */
      .then((res) => {
        if (res.status === 200) {
          return dispatch({
            type: 'LOGOUT',
          });
        }
      })
      .then(() => {
        setDidLogOut(true);
      })
      .catch((err) => {
        // const errorMsg = [];
        // _.forEach(err.response.data, e => {
        //   errorMsg.push(e[0]);
        // });
        // setData({
        //   ...data,
        //   errorMessage: errorMsg
        // });
      });
  };

  if (didLogOut) {
    setTimeout(() => setDidLogOut(false), 100);
    return <Redirect to="/" />;
  }

  return (
    <>
      {isAuthenticated ? (
        <LoginBtn
          color='inherit'
          className={classes.loginBtn}
          onClick={handleLogout}
        >
          Logout
        </LoginBtn>
      ) : (
        <Link to='/login'>
          <LoginBtn color='inherit'>Login</LoginBtn>
        </Link>
      )}
    </>
  );
};

export default LoginButton;
