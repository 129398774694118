import React from 'react';

const Returns = () => {
  return (
    <div className="outer-container">
      <div className="inner-container">
        <h1 id="returns-header">How do I return an order or cancel my subscription?</h1>
        <div className="paragraph-content">
          <div className="paragraph">
          To cancel a subscription or purchase, please contact and notify us of your request via email at services@localroastclub.com and your subscription will be canceled immediately. If you  cancel your subscription the same week as the delivery week (or receive coffee you really dislike and want to return), please just return the delivery to 12305 Deer Track, Austin TX 78727 within 7 days and you will receive a full refund. The coffee must be unopened and the customer is responsible for the cost of return shipping. If there is any feedback you could give me to keep your business, please contact services@localroastclub.com.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Returns;