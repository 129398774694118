import React from 'react';
import { AuthContext } from '../contexts/AuthContext';
import NavBar from './NavBar';
import Contents from './Contents';
import Footer from './Footer';

function Layout(props) {
  return (
    <div className="page">
      <AuthContext.Consumer>
        {
        (user) => {
          return(
          <>
            <NavBar user={user} />
            <Contents {...props} user={user} />
          </>
          )
        }
        }
      </AuthContext.Consumer>
      <Footer />
    </div>
  );
}

export default Layout;
