import _ from 'lodash';


const development = {
  host: 'http://localhost',
  port: '8181'
}

const production = {
  host: 'https://localroast-api.herokuapp.com',
  port: ''
}

export let env = {};
if (process.env.NODE_ENV === 'development') {
    _.merge(env, development);
} else {
    _.merge(env, production);
}