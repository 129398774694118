import React, {useState, useContext, useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';

// MUI styles
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';

import DisplayAddress from './DisplayAddress';

import {env} from '../../config/config.js';
import { AuthContext } from '../../contexts/AuthContext';

const { host, port } = env;


// for this make a call to stripe for the user address and throw it in session storage
// if they want to change the address have a button for them to update the address
// reuse the update address component in that case
// we'll also need a way to update the password and email address associated with the account

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  accountContent: {
    display: 'flex',
    width: '100%',
  },
  accountMenu: {
    display: 'flex',
    width: '20%',
  },
  displayData: {
    display: 'flex',
    width: '80%',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const Account = () => {
  const classes = useStyles();

  const { dispatch, state } = useContext(AuthContext);

  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [activeContent, setActiveContent] = useState('address');
  const [address, setAddress] = useState({});
  const [name, setName] = useState('');

  useEffect(() => {
    const checkAuthorization = async () => {
      const {isAuthenticated} = state;
      // set is authorized at the top of this
      // if user is authorized, then hit an endpoint to get their dashboard data
      if (isAuthenticated) {
        if (isAccessDenied) setIsAccessDenied(false);
      } else {
        try {
          const jwt = localStorage.getItem('token');
          await axios.get(`${host}:${port}/api/dashboard`, {headers: {'authorization': jwt}});
          if (isAccessDenied) setIsAccessDenied(false);
          await dispatch({
            type: 'AUTHORIZE'
          });
        } catch (e) {
          // if token is expired see if refreshToken works
          await checkRefreshToken();
        }
      }
    }
    checkAuthorization()
    // eslint-disable-next-line
  }, [isAccessDenied])

  const checkRefreshToken = async () => {
    try {
      const refreshJWT = localStorage.getItem('rfToken');
      const resp = await axios.post(`${host}:${port}/api/login/token`, {token: refreshJWT});
      const token = resp.data.accessToken;
      await dispatch({
        type: 'REFRESH',
        payload: { token }
      });
      if (isAccessDenied) setIsAccessDenied(false);
      await dispatch({
        type: 'AUTHORIZE'
      });
    } catch (err) {
      // redirect to login
      setIsAccessDenied(true);
    }
  }

  useEffect(() => {
    const getCustomer = async () => {
      const customer = await getAddress();
      const address = _.get(customer, 'data.shipping.address', '');
      setAddress(address);
      setName(_.get(customer, 'data.shipping.name', ''));
    }
    getCustomer();
  }, [address])

  const getAddress = async () => {
    const customerId = JSON.parse(localStorage.getItem('customerId'));
      try {
        return axios.post(`${host}:${port}/api/address/get_address`, {customerId});
      } catch (e) {
        // setFormError({...formError, isError: true, errorMsg: 'Customer does not exist'})
        // maybe set state with error message in the future
      }
  }

  const handleClick = (event) => {
    setActiveContent(event.target.getAttribute('name'))
  }

  if (isAccessDenied) {
    return <Redirect to={'/login'} />;
  }


  return (
     <div className="outer-container">
      <div className="inner-container">
        <h1>My Account</h1>
        <div className={classes.accountContent}>
          <div className={classes.accountMenu}>
            <div className={classes.root}>
              <Paper className={classes.paper}>
                <MenuList>
                  <MenuItem name="settings" onClick={handleClick}>Account Settings</MenuItem>
                  <MenuItem name="address" onClick={handleClick}>Address</MenuItem>
                  <MenuItem>Logout</MenuItem>
                </MenuList>
              </Paper>
            </div>
          </div>
          <div className={classes.displayData}>
            {activeContent && activeContent === 'address' ? <DisplayAddress address={address} name={name} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;