import React from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddCircle from '@material-ui/icons/AddCircle';

const Btn = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 140,
    border: 0,
    borderRadius: 3,
    color: 'black',
    height: 48,
  }
})(Button);

const AddBag = props => {
  const {addItem} = props;
  return (
    <div>
      <Btn type={_.get(props, 'options.type') || ''} disabled={_.get(props, 'options.disabled')} onClick={addItem}>
        <div>Add Another</div>
        <AddCircle id='plus' style={{ fontSize: 40 }} />
      </Btn>
    </div>
  );
};

export default AddBag;
