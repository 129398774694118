import React, {useState, useEffect, useContext} from 'react';
import { Redirect } from 'react-router-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import axios from 'axios';

import PaymentForm from './PaymentForm';
import UpdateAddress from '../address/UpdateAddress';

import {env} from '../../config/config.js';
import { AuthContext } from '../../contexts/AuthContext';
import './checkout.css';

const { host, port } = env;


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE}`);

const Payment = () => {
  const { dispatch, state } = useContext(AuthContext);

  const [isAddressSaved, setIsAddressSaved] = useState(false);
  const [isAccessDenied, setIsAccessDenied] = useState(false);

  useEffect(() => {
    const checkAuthorization = async () => {
      const {isAuthenticated} = state;
      // set is authorized at the top of this
      // if user is authorized, then hit an endpoint to get their dashboard data
      if (isAuthenticated) {
        if (isAccessDenied) setIsAccessDenied(false);
      } else {
        try {
          const jwt = localStorage.getItem('token');
          await axios.get(`${host}:${port}/api/dashboard`, {headers: {'authorization': jwt}});
          if (isAccessDenied) setIsAccessDenied(false);
          await dispatch({
            type: 'AUTHORIZE'
          });
        } catch (e) {
          // if token is expired see if refreshToken works
          await checkRefreshToken();
        }
      }
    }
    checkAuthorization()
    // eslint-disable-next-line
  }, [isAccessDenied])

  const checkRefreshToken = async () => {
    try {
      const refreshJWT = localStorage.getItem('rfToken');
      const resp = await axios.post(`${host}:${port}/api/login/token`, {token: refreshJWT});
      const token = resp.data.accessToken;
      await dispatch({
        type: 'REFRESH',
        payload: { token }
      });
      if (isAccessDenied) setIsAccessDenied(false);
      await dispatch({
        type: 'AUTHORIZE'
      });
    } catch (err) {
      // redirect to login
      setIsAccessDenied(true);
    }
  }

  if (isAccessDenied) {
    sessionStorage.setItem('continueToPayment', true)
    return <Redirect to={'/login'} />;
  }

  return (
    <div className="checkout-container">
      <div className="inner-container">
        <div className="form-container">
          <UpdateAddress setIsAddressSavedParent={setIsAddressSaved} />
      {isAddressSaved ? (
        <>
          <h1>Payment Information</h1>
            <Elements stripe={stripePromise}>
              <PaymentForm />
            </Elements>
        </>
      ) : null}
      </div>
      </div>
    </div>
  );
};

export default Payment;