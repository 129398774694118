import React, {useState} from 'react';
import axios from 'axios';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import DashboardBtn from './DashboardBtn';
import Progress from '../common/Progress';
import Alert from '@material-ui/lab/Alert';

import {env} from '../../config/config.js';

const { host, port } = env;

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '60%',
    height: '20%',
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: 'white',
    border: '0px solid black',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '&:focus': {
      outline: 'black auto 2px'
    },
  },
  closeModalLine: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  closeModalBox: {
    width: '10px',
    height: '20px',
    '&:hover': {
      cursor: 'pointer'
    },
  },
  contents: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: '100%'
  },
  text: {
    margin: '0px 10% 10px 10%'
  }
}));

const ConfirmModal = (props) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [isError, setIsError] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSubCancelled, setIsSubCancelled] = useState(false);

  const cancelSubscription = async () => {
    // first confirm that user is who they say they are w/ token and, grab the subscription id from the database
    // THEN run all of this
    const customerId = JSON.parse(localStorage.getItem('customerId'));
    try {
      setIsProcessing(true);
      const cancel = await axios.post(`${host}:${port}/api/cancel_subscription`, {
          customerId
      })
      setIsProcessing(false);
      if (_.get(cancel, 'data.error')) {
        setIsError(true);
      } else {
        props.updateSubDashboard();
        setIsSubCancelled(true);
      }
    } catch (e) {
      setIsProcessing(false);
      setIsError(true);
      // show error   
    }
      // get confirmation
      // 
  }

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className={classes.closeModalLine}>
        <div className={classes.closeModalBox} onClick={props.handleClose}>
          X
        </div>
      </div>
      <div className={classes.contents}>
                {isError
          ? (
                <div className='error'>
                    <Alert severity="error">Issue canceling, please send us an email</Alert>
                </div>
              )
          :
        (<>
        <h2 id='cancel-header' className={classes.text}>Cancel Subscription</h2>
        {isSubCancelled ? (
          <p>
            Cancelation complete
          </p>
        ) : (
          <>
            <p id='cancel-msg' className={classes.text}>
              We're sorry to see you go. If you have any thoughts on how we can better serve you, please reach out. Please confirm below to cancel.
            </p>
            {isProcessing ?
            <Progress /> : (
            <div onClick={cancelSubscription}>
              <DashboardBtn ctaText={'Confirm Cancel'} className={classes.confirmBtn} />
            </div>)}
          </>
        )}
        </>
        )}
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        {body}
      </Modal>
    </div>
  );
}

export default ConfirmModal