import React from 'react';

// MUI Styles
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import states from '../checkout/states'
import '../checkout/checkout.css';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '40vw',
    marginTop: '5vh',
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  form: {
    width: '45vw'
  }
}));


const AddressForm = (props) => {
  const classes = useStyles();

  const {handleAddressChange, address, isShippingAddress, isAddressSaved} = props;

  const addressType = isShippingAddress ? 'shipping' : 'billing';


  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField onChange={handleAddressChange} disabled={isAddressSaved} className={classes.form} required={true} id={`first-name-${addressType}`} name="firstName" label="First Name" variant="outlined" value={address.firstName} maxLength="8"/>
      <TextField onChange={handleAddressChange} disabled={isAddressSaved} className={classes.form} required={true} id={`last-name-${addressType}`} name="lastName" label="Last Name" variant="outlined" value={address.lastName} />
      <TextField onChange={handleAddressChange} disabled={isAddressSaved} className={classes.form} required={true} id={`address-${addressType}`} name="line1" label="Street Address" variant="outlined" value={address.line1} />
      <TextField onChange={handleAddressChange} disabled={isAddressSaved} className={classes.form} id={`address2-${addressType}`} name="line2" label="Apartment, suite, etc." variant="outlined" value={address.line2} />
      <TextField onChange={handleAddressChange} disabled={isAddressSaved} className={classes.form} required={true} id={`city-${addressType}`} name="city" label="City" variant="outlined" value={address.city} />
      <TextField
        id={`state-${addressType}`}
        select
        label="State"
        name="state"
        required={true}
        value={address.state}
        onChange={handleAddressChange}
        helperText="Please select your State"
        variant="outlined"
      >
      {states.map((option) => (
        <MenuItem disabled={isAddressSaved} required={true} key={option.value} value={option.value} id={isShippingAddress ? "shipping-state" : "billing-state"}
        >
          {option.name}
        </MenuItem>
      ))}
      </TextField>
      <TextField onChange={handleAddressChange} disabled={isAddressSaved} className={classes.form} required id={`zipcode-${addressType}`} name="postal_code" label="ZIP code" variant="outlined" value={address.postal_code}/>
    </form>
  );
};

export default AddressForm;