import React, {useState, useEffect} from 'react';
import axios from 'axios';
import _ from 'lodash';

// MUI Styles
import Alert from '@material-ui/lab/Alert';

import LrcButton from '../common/LrcButton';
import AddressForm from './AddressForm';

// Config
import {env} from '../../config/config.js';
const { host, port } = env;



const UpdateAddress = (props) => {

  const [isAddressSaved, setIsAddressSaved] = useState(false);

  const [formError, setFormError] = useState({isError: false, errorMsg: ''});

  const [shippingAddress, setShippingAddress] = useState({
    firstName: '',
    lastName: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    postal_code: '',
  });


  const validateFormData = (event) => {
    const property = event.target.name;
    const value = event.target.value;
    const elValue = event.currentTarget.value;
    const isTextOnly = property === 'firstName' || property === 'lastName' || property === 'city';
    if (value.match(/[0-9]/) && isTextOnly) {
      return true;
    }
    if (value.match(/[a-z]|[A-Z]|\s/) && property === 'postal_code') {
      return true;
    }
    if ((property === 'firstName' || property === 'lastName' || property === 'line1' || property === 'line2') && elValue.length > 35) {
      return true;
    }
    if (property === 'city' && elValue.length > 17) {
      return true;
    }
    if (property === 'postal_code' && elValue.length > 5) {
      return true;
    }
  }

  const handleAddressChange = (event) => {
    const property = event.target.name;
    const isNotValidEntry = validateFormData(event);
    if (isNotValidEntry) {
      // display an error and block typing
    } else {
      if (event.currentTarget.id.includes("shipping")) {
        setShippingAddress({ ...shippingAddress, [property]: event.target.value });
      }
    }
  };

  const validateAddressSubmission = (address) => {
    const {
      postal_code,
    } = address;

    const isBlankField = _.reduce(address, (acc, value, key) => {
      if (key !== 'line2') {
        if (!value.length) return acc && false;
      }
      return acc && true;
    }, true)
    
    if (!isBlankField) {
      setFormError({...formError, isError: true, errorMsg: 'Field is required'})
      return false;
    }
    if (postal_code.length < 5) {  
      setFormError({...formError, isError: true, errorMsg: 'Not a valid zipcode'})
      return false;
    }
    return true;
  }

  useEffect(
    () => {
      const updateAddress = async () => {
        if (isAddressSaved) {
          const customerId = JSON.parse(localStorage.getItem('customerId'));
          try {
            await axios.post(`${host}:${port}/api/address/save_address`, {customerId, shippingAddress});
          } catch (e) {
            setFormError({...formError, isError: true, errorMsg: 'Customer does not exist'})
            // maybe set state with error message in the future
          }
        }
      }
      updateAddress();
    },
    // eslint-disable-next-line
    [isAddressSaved]
  )

  const saveAddress = async () => {
    const isValidAddress = _.reduce([shippingAddress], (acc, address) => {
      return acc && validateAddressSubmission(address);
    }, true);
    if (isValidAddress) {
      setIsAddressSaved(!isAddressSaved);
      localStorage.setItem('firstName', shippingAddress.firstName);
      if (props && props.setIsAddressSavedParent) props.setIsAddressSavedParent(!isAddressSaved);
      setFormError({...formError, isError: false, errorMsg: ''})
    }
  }

  return (
    <>
      {formError.isError ? (<Alert severity="error">{formError.errorMsg}</Alert>) : null}
      {props.fromAccountPage ? null : (<h1>Shipping Address</h1>)}
      <AddressForm handleAddressChange={handleAddressChange} address={shippingAddress} isShippingAddress={true} isAddressSaved={isAddressSaved}/>
      <div onClick={saveAddress}>
        <LrcButton ctaText={isAddressSaved ? 'Edit Address' : 'Confirm Address'} />
      </div>
    </>
  );
};

export default UpdateAddress;