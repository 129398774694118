import React from 'react';

const AboutUs = () => {
  return (
    <div className="outer-container">
    <div className="inner-container">
      <h1>About Us</h1>
        <div className="paragraph-content">
        <h3>Local Roast</h3>
        <h4>Austin Coffee delivered to your door</h4>
          <div className="paragraph">        
            Local Roast is a coffee subscription service that allows customers to choose what type of coffee bean roast they would like sent to them in the mail each month. The coffee is delivered once every four weeks, and a charge will be made to your card each four weeks when it ships.
          </div>
          <div className="paragraph">
            We're dedicated to providing you with quality service. If you get a coffee you don't like, we'll replace it free of charge with another bag until you land on one you like.
          </div>
        </div>
    </div>
  </div>
  );
};

export default AboutUs;