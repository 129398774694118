import React, {useEffect, useState} from 'react';
import getQty from '../../utility/getQty';

const Total = (props) => {
  const {orderItems, style} = props;

  const [bagNumber, setBagNumber] = useState(1);
  const [estTotal, setEstTotal] = useState(0);

  useEffect(() => {
    const qty = getQty(orderItems);
    setBagNumber(qty);
  }, [props])

  useEffect(() => {
    setEstTotal(calculateTotal());
  }, [estTotal, bagNumber])

  const calculateTotal = () => {
    let total;
    const qty = getQty(orderItems);
    setBagNumber(qty);
    const subscriptionChoice = localStorage.getItem('subscriptionChoice');
    if (subscriptionChoice === 'Blends') {
      if (qty <= 2) {
        total = qty === 1 ? 17.97 : 32.97;
      } else if (qty > 2 && qty < 5) {
        total = 32.97 + ((qty - 2) * 8);
      } else {
        total = 48.97 + ((qty - 4) * 10);
      }
    } else if (subscriptionChoice === 'Mix') {
      if (qty <= 2) {
        total = qty === 1 ? 18.97 : 34.97;
      } else {
        total = 34.97 + ((qty - 2) * 11);
      }
    } else if (subscriptionChoice === 'Single Origin') {
      if (qty <= 2) {
        total = qty === 1 ? 19.97 : 36.97;
      } else {
        total = 36.97 + ((qty - 2) * 12);
      }
    }
    return total;
  }

  return (
    <>
      <div className="total" style={style}>Total: ${estTotal}</div>
    </>
  );
};

export default Total;