import React from 'react';
import _ from 'lodash';
import RoastListItem from './RoastListItem';

const RoastList = props => {
  return (
    <div>
      {_.map(props.roastItems, (item, key) => {
        return (
          <RoastListItem
            choiceKey={props.choiceKey}
            item={item}
            index={key + 1}
            key={key}
            customSelection={props.customSelection}
            checkForAllFields={props.checkForAllFields}
            roastItems={props.roastItems}
            removeItem={props.removeItem}
            hasSubmitted={props.hasSubmitted}
            updateOrderChoices={props.updateOrderChoices}
          />
        );
      })}
    </div>
  );
};

export default RoastList;
