import React from 'react';
import ListItem from './ListItem';
import RoasterDisplay from '../roasters/RoasterDisplay';

const CopyL1 = () => {
  return (
    <div className="copy-l1">
      <div>
        <h2 className="title">What you get when you join</h2>
      </div>
      <RoasterDisplay />
      <div className="list-items">
        <div className="col-list">
          <div className="col-1">
            <ListItem>Keeping it local with coffee roasted only in Austin</ListItem>
            <ListItem>100+ types of premium coffee</ListItem>
            <ListItem>FListIteml 12 to 16-oz bags for variety</ListItem>
          </div>
          <div className="col-2">
            <ListItem>A set-and-relax subscription service</ListItem>
            <ListItem>Peace of mind knowing we’re finding delicious coffee for you every month</ListItem>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyL1;
