export const states = [
  {
      "value": "AL",
      "name": "Alabama",
      "enabled": true
  },
  {
      "value": "AK",
      "name": "Alaska",
      "enabled": true
  },
  {
      "value": "AZ",
      "name": "Arizona",
      "enabled": true
  },
  {
      "value": "AR",
      "name": "Arkansas",
      "enabled": true
  },
  {
      "value": "CA",
      "name": "California",
      "enabled": true
  },
  {
      "value": "CO",
      "name": "Colorado",
      "enabled": true
  },
  {
      "value": "CT",
      "name": "Connecticut",
      "enabled": true
  },
  {
      "value": "DE",
      "name": "Delaware",
      "enabled": true
  },
  {
      "value": "FL",
      "name": "Florida",
      "enabled": true
  },
  {
      "value": "GA",
      "name": "Georgia",
      "enabled": true
  },
  {
      "value": "HI",
      "name": "Hawaii",
      "enabled": true
  },
  {
      "value": "ID",
      "name": "Idaho",
      "enabled": true
  },
  {
      "value": "IL",
      "name": "Illinois",
      "enabled": true
  },
  {
      "value": "IN",
      "name": "Indiana",
      "enabled": true
  },
  {
      "value": "IA",
      "name": "Iowa",
      "enabled": true
  },
  {
      "value": "KS",
      "name": "Kansas",
      "enabled": true
  },
  {
      "value": "KY",
      "name": "Kentucky",
      "enabled": true
  },
  {
      "value": "LA",
      "name": "Louisiana",
      "enabled": true
  },
  {
      "value": "ME",
      "name": "Maine",
      "enabled": true
  },
  {
      "value": "MH",
      "name": "Marshall Islands",
      "enabled": true
  },
  {
      "value": "MD",
      "name": "Maryland",
      "enabled": true
  },
  {
      "value": "MA",
      "name": "Massachusetts",
      "enabled": true
  },
  {
      "value": "MI",
      "name": "Michigan",
      "enabled": true
  },
  {
      "value": "FM",
      "name": "Micronesia",
      "enabled": true
  },
  {
      "value": "MN",
      "name": "Minnesota",
      "enabled": true
  },
  {
      "value": "MS",
      "name": "Mississippi",
      "enabled": true
  },
  {
      "value": "MO",
      "name": "Missouri",
      "enabled": true
  },
  {
      "value": "MT",
      "name": "Montana",
      "enabled": true
  },
  {
      "value": "NE",
      "name": "Nebraska",
      "enabled": true
  },
  {
      "value": "NV",
      "name": "Nevada",
      "enabled": true
  },
  {
      "value": "NH",
      "name": "New Hampshire",
      "enabled": true
  },
  {
      "value": "NJ",
      "name": "New Jersey",
      "enabled": true
  },
  {
      "value": "NM",
      "name": "New Mexico",
      "enabled": true
  },
  {
      "value": "NY",
      "name": "New York",
      "enabled": true
  },
  {
      "value": "NC",
      "name": "North Carolina",
      "enabled": true
  },
  {
      "value": "ND",
      "name": "North Dakota",
      "enabled": true
  },
  {
      "value": "MP",
      "name": "Northern Mariana Islands",
      "enabled": true
  },
  {
      "value": "OH",
      "name": "Ohio",
      "enabled": true
  },
  {
      "value": "OK",
      "name": "Oklahoma",
      "enabled": true
  },
  {
      "value": "OR",
      "name": "Oregon",
      "enabled": true
  },
  {
      "value": "PA",
      "name": "Pennsylvania",
      "enabled": true
  },
  {
      "value": "RI",
      "name": "Rhode Island",
      "enabled": true
  },
  {
      "value": "SC",
      "name": "South Carolina",
      "enabled": true
  },
  {
      "value": "SD",
      "name": "South Dakota",
      "enabled": true
  },
  {
      "value": "TN",
      "name": "Tennessee",
      "enabled": true
  },
  {
      "value": "TX",
      "name": "Texas",
      "enabled": true
  },
  {
      "value": "UT",
      "name": "Utah",
      "enabled": true
  },
  {
      "value": "VT",
      "name": "Vermont",
      "enabled": true
  },
  {
      "value": "VA",
      "name": "Virginia",
      "enabled": true
  },
  {
      "value": "WA",
      "name": "Washington",
      "enabled": true
  },
  {
      "value": "DC",
      "name": "Washington DC",
      "enabled": true
  },
  {
      "value": "WV",
      "name": "West Virginia",
      "enabled": true
  },
  {
      "value": "WI",
      "name": "Wisconsin",
      "enabled": true
  },
  {
      "value": "WY",
      "name": "Wyoming",
      "enabled": true
  },
]

export default states;