import { createContext } from 'react';

export const AuthContext = createContext();
export const initialState = {
  isAuthenticated: false,
  user: null,
  token: null
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('rfToken', action.payload.refreshToken);
      localStorage.setItem('customerId', JSON.stringify(action.payload.customerId)); // TODO: change to sessionStorage
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        customerId: action.payload.customerId
      };
    case 'LOGOUT':
      localStorage.clear();
      sessionStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    case 'AUTHORIZE':
      return {
        ...state,
        isAuthenticated: true
      };
    case 'REFRESH':
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token
      };
    default:
      return state;
  }
};

// TODO: we want to extend context functionalities to an authorizedContext
  // it would check the token against an api on any page refresh
  // see if there is a token then validate it
  // if not, user is not logged in
