function getMondays(nextMonth) {
  const currentDate = new Date();
  const d = nextMonth ? new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1) : currentDate;
  const month = d.getMonth();
  const mondays = [];

  d.setDate(1);

  // Get the first Monday in the month
  while (d.getDay() !== 1) {
      d.setDate(d.getDate() + 1);
  }

  // Get all the other Mondays in the month
  while (d.getMonth() === month) {
      mondays.push(new Date(d.getTime()));
      d.setDate(d.getDate() + 7);
  }

  return mondays;
}

function getMonthName(index) {
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
  ];
  return monthNames[index];
}

export function getNextShipDate() {
  const mondays = getMondays();
  let nextShipDate = mondays[1];

  // check that given month's monday is in the future
  // if not get the next set of mondays to use as the next roast date
  if (mondays[1].getTime() < new Date().getTime()) {
    const nextMonthMondays = getMondays(true);
    nextShipDate = nextMonthMondays[1];
  }
  console.log(nextShipDate);

  const year = nextShipDate.getUTCFullYear();
  const month = getMonthName(nextShipDate.getMonth());
  const day = nextShipDate.getUTCDate();
  const nextShipDateStr = `${month} ${day}, ${year}`;

  return nextShipDateStr
}

