import React, {useState, useEffect, useContext} from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';

// MUI Components/Styling
import Alert from '@material-ui/lab/Alert';

// Components
import OrderList from '../common/OrderList';
import LrcButton from '../common/LrcButton';
import Progress from '../common/Progress';
import Total from './Total';

import getQty from '../../utility/getQty';

// Styling
import './subscription.css';

import { AuthContext } from '../../contexts/AuthContext';
import {env} from '../../config/config.js';

const { host, port } = env;

const ReviewOrder = props => {
  const { dispatch, state } = useContext(AuthContext);
  const {isAuthenticated} = state;

  let updating;
  if (props.location && props.location.state) {
    (updating = props.location.state.updating);
  }

  const [isError, setIsError] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

  useEffect(() => {
    const checkAuthorization = async () => {
      // set is authorized at the top of this
      // if user is authorized, then hit an endpoint to get their dashboard data
      const jwt = localStorage.getItem('token');
      if (!isAuthenticated && jwt) {
        try {
          await axios.get(`${host}:${port}/api/payment`, {headers: {'authorization': jwt}});
          await dispatch({
            type: 'AUTHORIZE'
          });
        } catch (e) {
          // try looking for refreshToken
          await checkRefreshToken();
        }
      }
    }
    checkAuthorization()
    // eslint-disable-next-line
  }, [])

  const checkRefreshToken = async () => {
    try {
      const refreshJWT = localStorage.getItem('rfToken');
      const resp = await axios.post(`${host}:${port}/api/login/token`, {token: refreshJWT});
      const token = resp.data.accessToken;
      await dispatch({
        type: 'REFRESH',
        payload: { token }
      });
      await dispatch({
        type: 'AUTHORIZE'
      });
    } catch (err) {
      // redirect to login
    }
  }

  useEffect(() => {
    let items = _.get(props, 'location.state.roastItems');
    if (!items) {
      const subscriptionChoice = localStorage.getItem('subscriptionChoice');
      if (subscriptionChoice === 'Blends') {
        items = JSON.parse(localStorage.getItem('orderChoice1'));
      } else if (subscriptionChoice === 'Single Origin') {
        items = JSON.parse(localStorage.getItem('orderChoice3'));
      } else {
        items = JSON.parse(localStorage.getItem('orderChoice2'));
      }
    }
    setOrderItems(items);
    // eslint-disable-next-line
  }, []);



  const submitUpdate = async () => {
    const customerId = JSON.parse(localStorage.getItem('customerId'));
    try {
      setIsProcessing(true);
      const update = await axios.post(`${host}:${port}/api/update_subscription`, {
        customerId: customerId,
        qty: getQty(orderItems),
        items: orderItems,
        firstName: localStorage.getItem('firstName'),
        subscriptionChoice: localStorage.getItem('subscriptionChoice'),
      })
      setIsProcessing(false);
      if (_.get(update, 'data.error')) {
        setIsError(true);
      } else {
        setIsUpdateSuccess(true);
      }
    } catch (e) {
      setIsProcessing(false);
      setIsError(true);
    }
  }

  return (
    <div className="subscription-container">
      <div className="inner-review-container">
        {isUpdateSuccess ? 
        (<div>
          <h1>Order updated successfully</h1>
          <OrderList orderItems={orderItems} />
          <Link to='/dashboard'>
            <LrcButton ctaText={'Dashboard'}/>
          </Link>
        </div>) :
        (<>
        <h4>Review Your Order:</h4>
        {isError
          ? (
                <div className='error'>
                    <Alert severity="error">Issue canceling, please send us an email</Alert>
                </div>
              )
          : null}
        <OrderList orderItems={orderItems} />
        {/* <div className="total">Total: ${calculateTotal()}</div> */}
        <Total orderItems={orderItems} style={{width: '60vw', margin: '3vw'}} />
      {isProcessing ? <Progress /> : 
      (<div className="continue-payment">
        <Link to={{pathname: '/subscribe/choice', state: {updating}}}>
          <LrcButton ctaText={'Back'} />
        </Link>
        {updating ? 
          (<div onClick={submitUpdate}>
            <LrcButton ctaText={'Finish'} />
          </div>) :
          <Link to={{pathname: isAuthenticated ? '/payment' : '/login'}}>
            <LrcButton ctaText={'Payment'} />
          </Link>
        }
      </div>
      )}
      </>)}
      </div>
    </div>
  );
};

export default ReviewOrder;
